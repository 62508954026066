import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";


function Records() {
  const [data, setData] = useState(null);
  const [focusedIndex, setFocusedIndex] = useState(0);
  const [isAuth, setIsAuth] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [isSuperuser, setIsSuperuser] = useState(false); // State to store superuser status
  const navigate = useNavigate();

  useEffect(() => {
    if (localStorage.getItem('access_token') !== null) {
      setIsAuth(true); // Set authentication status to true if access token is present
      fetch('https://scoreboard.backenddd.in/GetAllRecord/', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        },
      })
        .then(response => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .then(data => {
          setData(data.records || []); // Update state with fetched data
          setIsSuperuser(data.is_superuser);
        })
        .catch(error => {
          console.error('There was a problem with the fetch operation:', error);
        });
    }
  }, []);


  // ####################################################################
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(4);
  const totalPages = Math.ceil((data && data.length) / recordsPerPage);

  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = data && data.slice(indexOfFirstRecord, indexOfLastRecord);

  const paginate = () => {
    // Move to the next page
    setCurrentPage((prevPage) => (prevPage === totalPages ? 1 : prevPage + 1));
  };

  useEffect(() => {
    if (data && data.length > 0) {
      const focusOnRecord = (index) => {
        setFocusedIndex(index);

        const timer = setTimeout(() => {
          const nextIndex = (index + 1) % data.length;

          // If the next record is on a new page, paginate to the next page
          if (nextIndex === 0) {
            paginate();

            if (currentPage === totalPages && nextIndex === 0) {
              // Navigate to "/slider" page
              navigate('/slider');
            }
          }

          setFocusedIndex(nextIndex);
        }, 5000);

        return () => clearTimeout(timer);
      };

      // Start focusing on the first record
      focusOnRecord(focusedIndex);

      return () => setFocusedIndex(0); // Reset focusedIndex when the component unmounts
    }
  }, [focusedIndex, data, currentPage, totalPages, paginate, navigate]);

  // ##########################################################################


  // useEffect(() => {
  //   if (data && data.length > 0) {
  //     // Function to focus on each record
  //     const focusOnRecord = (index) => {
  //       setFocusedIndex(index);

  //       // Reset focus after 5 seconds
  //       const timer = setTimeout(() => {
  //         const nextIndex = (index + 1) % data.length;
  //         setFocusedIndex(nextIndex);
  //       }, 5000);

  //       return () => clearTimeout(timer); // Clear timeout on component unmount or when the focus changes
  //     };

  //     const timer = focusOnRecord(focusedIndex);

  //     return () => clearTimeout(timer); // Clear timeout on component unmount or when the focus changes
  //   }
  // }, [focusedIndex, data]);


  const openModal = (record) => {
    setSelectedRecord(record);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    setSelectedRecord(null);
  };



  const handleUpdate = () => {
    if (selectedRecord) {
      const formData = new FormData();
      formData.append('id', selectedRecord.id);
      formData.append('name', document.getElementById('recordName').value);
      formData.append('resume_forwarded', document.getElementById('forwarded').value);
      formData.append('pipline', document.getElementById('pipline').value);
      formData.append('turned_up', document.getElementById('turned_up').value);
      formData.append('selected', document.getElementById('selected').value);
      formData.append('target', document.getElementById('target').value);
      // Handle file upload, if available
      const imageInput = document.getElementById('image');
      if (imageInput.files.length > 0) {
        formData.append('image', imageInput.files[0]);
      }

      fetch('https://scoreboard.backenddd.in/UpdateRecord/', {
        method: 'PUT',
        body: formData,
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
            console.log(response)
          }
          return response.json();
        })
        .then((data) => {
          console.log('Updated record:', data);
          closeModal();
          // Re-fetch all records after successful update
          fetch('https://scoreboard.backenddd.in/GetAllRecord/')
            .then((response) => {
              if (!response.ok) {
                throw new Error('Network response was not ok');
              }
              return response.json();
            })
            .then((data) => {
              setData(data); // Update the state with new data
            })
            .catch((error) => {
              console.error('There was a problem with the fetch operation:', error);
              // Handle errors here
            });
        })
        .catch((error) => {
          console.error('There was a problem with the fetch operation:', error);
          // Handle errors here
        });
    }
  };


  // Function to delete a record
  const handleDelete = (id) => {
    fetch(`https://scoreboard.backenddd.in/DelRecord/${id}/`, {
      method: 'DELETE',
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        console.log('Deleted record:', data);
        // Re-fetch all records after successful delete
        fetch('https://scoreboard.backenddd.in/GetAllRecord/')
          .then((response) => {
            if (!response.ok) {
              throw new Error('Network response was not ok');
            }
            return response.json();
          })
          .then((data) => {
            setData(data); // Update the state with new data
          })
          .catch((error) => {
            console.error('There was a problem with the fetch operation:', error);
            // Handle errors here
          });
      })
      .catch((error) => {
        console.error('There was a problem with the fetch operation:', error);
        // Handle errors here
      });
  };

  const getColorForProgress = (percentage) => {
    if (percentage >= 90) {
      return 'green';
    } else if (percentage >= 70) {
      return 'blue';
    } else if (percentage >= 50) {
      return 'yellow';
    }
    else if (percentage >= 20) {
      return '#a21caf';
    }
    else {
      return 'red';
    }
  };


  return (
    <>
      <section>
        <div>
          <div>
            <div>
              <div class="max-w-full overflow-x-auto">

                <table class="table">
                  <thead>
                    <tr>
                      <th scope="col">HR</th>
                      <th scope="col">Name</th>
                      <th scope="col">Resume Forwarded</th>
                      <th scope="col">Turned Up</th>
                      <th scope="col">Selected Candidate</th>
                      {isSuperuser && (
                        <th
                          class="col"
                        >
                          Options
                        </th>
                      )}
                    </tr>
                  </thead>
                  <tbody class="table-group-divider">
                    {currentRecords &&
                      currentRecords.map((record, index) => (
                        <tr key={index} className={` ${index === focusedIndex ? 'actives' : ''}`}>
                          <td style={{ textAlign: "center", width: "10%" }}>
                            <img src={`https://scoreboard.backenddd.in${record.image}`} style={{ width: "150px", height: "20vh", margin: "auto" }} className={` ${index === focusedIndex ? 'text-3xl rounded-full' : ''}`} alt="" />
                          </td>
                          <td className={` ${index === focusedIndex ? 'text-3xl' : ''}`}>{record.name}</td>
                          <td className={` ${index === focusedIndex ? 'text-3xl' : ''}`}>{record.resume_forwarded}</td>
                          <td className={` ${index === focusedIndex ? 'text-3xl' : ''}`}>{record.turned_up}</td>
                          <td className={` ${index === focusedIndex ? 'text-3xl' : ''}`}>{record.selected}</td>
                          {isSuperuser && (
                            <td className={` ${index === focusedIndex ? 'focused-textprogressbar text-lg p-2' : 'text-dark border-b border-l border-[#E8E8E8] bg-white dark:bg-dark-3 dark:border-dark dark:text-dark-7 py-2 px-2 text-center text-base font-medium'}`}>
                              <button className="btn btn-primary" onClick={() => openModal(record)}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil" viewBox="0 0 16 16">
                                  <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325" />
                                </svg>
                              </button>
                            </td>
                          )}
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
              <ul className="pagination">
                {Array.from({ length: totalPages }, (_, i) => (
                  <li key={i} className={`page-item ${currentPage === i + 1 ? 'active' : ''}`}>
                    <button className="page-link" onClick={() => setCurrentPage(i + 1)}>
                      {i + 1}
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </section>


      {showModal && (
        <div className="modal" tabIndex="-1" role="dialog" style={{ display: 'block' }}>
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Edit Record</h5>
                <button type="button" className="btn-close" onClick={closeModal}></button>
              </div>
              <div className="modal-body">
                {selectedRecord && (
                  <form>
                    <input type="text" className="form-control" id="recordId" value={selectedRecord.id} />

                    <div className="mb-3">
                      <label htmlFor="recordName" className="form-label">Record Name</label>
                      <input type="text" className="form-control" id="recordName" value={selectedRecord.name} onChange={(e) => setSelectedRecord({ ...selectedRecord, name: e.target.value })} />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="forwarded" className="form-label">Resume Forwarded</label>
                      <input type="text" className="form-control" id="forwarded" value={selectedRecord.resume_forwarded} onChange={(e) => setSelectedRecord({ ...selectedRecord, resume_forwarded: e.target.value })} />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="turned_up" className="form-label">Turned Up</label>
                      <input type="text" className="form-control" id="turned_up" value={selectedRecord.turned_up} onChange={(e) => setSelectedRecord({ ...selectedRecord, turned_up: e.target.value })} />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="selected" className="form-label">Selected</label>
                      <input type="text" className="form-control" id="selected" value={selectedRecord.selected} onChange={(e) => setSelectedRecord({ ...selectedRecord, selected: e.target.value })} />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="target" className="form-label">Target</label>
                      <input type="text" className="form-control" id="target" value={selectedRecord.target} onChange={(e) => setSelectedRecord({ ...selectedRecord, target: e.target.value })} />
                    </div>
                    <input type="text" className="form-control" id="img" value={selectedRecord.image} />
                    <div className="mb-3">
                      <label htmlFor="image" className="form-label">Image URL</label>
                      <input type="file" className="form-control" id="image" onChange={(e) => setSelectedRecord({ ...selectedRecord, image: e.target.value })} />
                    </div>
                    <button type="button" className="btn btn-primary" onClick={handleUpdate}>
                      Save Changes
                    </button>
                    <td>
                      <button className="btn btn-danger" onClick={() => handleDelete(selectedRecord.id)}>
                        Delete
                      </button>
                    </td>
                  </form>
                )}
              </div>
            </div>
          </div>
        </div>
      )}

    </>
  );
}
export default Records;