import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from "react-router-dom"; 
import { Link } from "react-router-dom";
import Navbar from "./../NavBar";


const CreateRecord = () => {
    const navigate = useNavigate();
    const [data, setData] = useState(null);
  
    const [isAuth, setIsAuth] = useState(false);

    
    useEffect(() => {
        if (localStorage.getItem('access_token') !== null) {
          setIsAuth(true); // Set authentication status to true if access token is present
          fetch('https://scoreboard.backenddd.in/Docladder/daily-user-records/', {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('access_token')}`,
            },
          })
            .then(response => {
              if (!response.ok) {
                throw new Error('Network response was not ok');
              }
              return response.json();
            })
            .then(data => {
              setData(data.records || []); // Update state with fetched data
    
            })
            .catch(error => {
              console.error('There was a problem with the fetch operation:', error);
            });
        }
      }, []);
    

    const [formData, setFormData] = useState({
        name: '',
        password: '',
        username: '',

        resume_forwarded: 0,
        pipline: 0,
        selected: 0,
        target: 0,
        turned_up: 0,
        image: 'null'
    });

    const [successMessage, setSuccessMessage] = useState('');
    const handleChange = e => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        setFormData({ ...formData, image: file });
    };

    const handleSubmit = async e => {
        e.preventDefault();
        
        try {
            await axios.post('https://scoreboard.backenddd.in/create_Teammember_api/', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                  },
            });
            // Optionally, perform actions upon successful creation
            setSuccessMessage('Record created successfully!');
            console.log('Record created successfully!');

            setFormData({
                name: '',
                password: '',
                username: '',
                resume_forwarded: 0,
                pipline: 0,
                selected: 0,
                target: 0,
                turned_up: 0,
                image: null
            });
            // navigate("/viewers" , { replace: true, state: { 'newUser':  formData.name } });

        } catch (error) {
            console.error('Error creating record:', error);
            console.error('Error creating record:', error.response.status);
            console.error('Error message:', error.response.data);
            console.error('Error creating record:', error.request.responseText);
        }
    };

    return (
        <>
         <Navbar />
            <div>
            {successMessage && (
                    <div class="bg-green-200 text-green-700 border border-green-600 px-4 py-3 rounded-md my-4">
                        {successMessage}
                    </div>
                )}
                <div class="container mx-auto px-4" style={{ width: "50%", margin: "auto", marginTop: "35px" }}>
                    <div className="container" style={{display:"flex", justifyContent:"space-between"}}>
                        <h1 class="text-3xl font-bold text-center">Add HR Associate</h1>
                        <Link to="/records" style={{marginTop: "8px", border: "1px solid blue", padding: "15px", borderRadius: "7px 15px"}}>All Records</Link>
                    </div>


                    <form onSubmit={handleSubmit} method="post" encType="multipart/form-data">
                        <div class="grid grid-cols-2 gap-3">
                            <div class="col-span-2">
                                <label class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300" for="name">Name</label>
                                <input class="block w-full px-4 py-2 text-sm text-gray-700 bg-white border rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring" value={formData.name} onChange={handleChange} type="text" id="name" name="name" required />
                            </div>
                            <div class="col-span-2">
                                <label class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300" for="name">Username</label>
                                <input class="block w-full px-4 py-2 text-sm text-gray-700 bg-white border rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring" value={formData.username} onChange={handleChange} type="text" id="username" name="username" required />
                            </div>
                            <div class="col-span-2">
                                <label class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300" for="name">Password</label>
                                <input class="block w-full px-4 py-2 text-sm text-gray-700 bg-white border rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring" value={formData.password} onChange={handleChange} type="text" id="password" name="password" required />
                            </div>
                            <div class="col-span-2">
                                <label class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300" for="resume_forwarded">Resumes Forwarded</label>
                                <input class="block w-full px-4 py-2 text-sm text-gray-700 bg-white border rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring" type="number" value={formData.resume_forwarded} onChange={handleChange} id="resume_forwarded" name="resume_forwarded" required />
                            </div>
                            <div class="col-span-2">
                                <label class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300" for="pipline">Pipeline</label>
                                <input class="block w-full px-4 py-2 text-sm text-gray-700 bg-white border rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring" type="number" value={formData.pipline} onChange={handleChange} id="pipline" name="pipline" required />
                            </div>
                            <div class="col-span-2">
                                <label class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300" for="turned_up">Turned Up</label>
                                <input class="block w-full px-4 py-2 text-sm text-gray-700 bg-white border rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring" type="number" id="turned_up" value={formData.turned_up} onChange={handleChange} name="turned_up" required />
                            </div>
                            <div class="col-span-2">
                                <label class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300" for="selected">Selected</label>
                                <input class="block w-full px-4 py-2 text-sm text-gray-700 bg-white border rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring" type="number" id="selected" value={formData.selected} onChange={handleChange} name="selected" required />
                            </div>
                            <div class="col-span-2">
                                <label class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300" for="target">Target</label>
                                <input class="block w-full px-4 py-2 text-sm text-gray-700 bg-white border rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring" type="number" id="target" name="target" value={formData.target} onChange={handleChange} required />
                            </div>
                            <div class="col-span-2">
                                <label class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300" for="img">Image URL</label>
                                <input class="block w-full px-4 py-2 text-sm text-gray-700 bg-white border rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring" type="file" id="image" onChange={handleImageChange} name="image" required />
                            </div>
                        </div>

                        <div class="flex justify-end mt-4">
                            <button type="submit" class="px-4 py-2 text-sm font-medium text-white bg-blue-500 rounded-md hover:bg-blue-600 focus:outline-none focus:ring">Create</button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}

export default CreateRecord