// import React, { useState, useEffect } from "react";
// import { useLocation } from "react-router-dom";
// import axios from "axios";
// import Navbar from "../NavBar";
// import { Link } from "react-router-dom";
// import { useNavigate } from "react-router-dom";
// import './../Leaderboard/Tasks/docladderTasks.css';
// // start for multi select mui
// import { useTheme } from '@mui/material/styles';


// const ITEM_HEIGHT = 48;
// const ITEM_PADDING_TOP = 8;

// // end for multi select mui


// function UserWiseTeammember() {

//     const location = useLocation();
//     const [userPermissions, setUserPermissions] = useState("");

//     useEffect(() => {
//         // Extract the state from location
//         if (location.state && location.state.userPermissions) {
//             setUserPermissions(location.state.userPermissions);
//         }
//     }, [location.state]);

//     console.log("State Value (userPermissions): ", userPermissions);

//     const [data, setData] = useState(null);
//     const [isModalOpen, setIsModalOpen] = useState(false);
//     const [selectedTask, setSelectedTask] = useState(null);
//     const [tasks, setTasks] = useState(null);
//     const [targets, setTargets] = useState(null);
//     const [inputValues, setInputValues] = useState({ value: '' });
//     const [targetDays, setTargetDays] = useState('');
//     const [selectedTaskName, setSelectedTaskName] = useState('');
//     const [ename, setEname] = useState('');
//     const [dateJoined, setDateJoined] = useState('');
//     const [dateHandovered, setDateHandovered] = useState('');
//     const navigate = useNavigate();
//     const [users, setUsers] = useState([]);
//     const [topic, setTopic] = useState([]);
//     const [old_emp_training_topic, setold_emp_training_topic] = useState([]);

//     const [tdate, setTdate] = useState([]);

//     const [selectedUsers, setSelectedUsers] = useState([]);

//     const handleSignOut = () => {
//         localStorage.removeItem("access_token");
//         navigate('/');
//     };

//     useEffect(() => {
//         const fetchData = async () => {
//             try {
//                 const token = localStorage.getItem('access_token');
//                 console.log(token)
//                 if (token) {
//                     const response = await axios.get(
//                         'https://scoreboard.backenddd.in/Docladder/GetmemberDatafromUsername/',
//                         {
//                             headers: {
//                                 Authorization: `Bearer ${localStorage.getItem('access_token')}`,
//                             },
//                         }
//                     );

//                     const allusers = await axios.get('https://scoreboard.backenddd.in/get-all-users/',
//                         {
//                             headers: {
//                                 Authorization: `Bearer ${localStorage.getItem('access_token')}`,
//                             },
//                         }
//                     )
//                     console.log("All Users: ", allusers.data)
//                     setUsers(allusers.data)

//                     console.log("Data: ", response.data)

//                     setData(response.data.member);
//                     setTasks(response.data.member.currentVal);
//                     setTargets(response.data.member.tasks);
//                     setEname(response.data.member.ename); // Set ename
//                     setDateJoined(response.data.member.date_joined); // Set date_joined
//                     setDateHandovered(response.data.member.date_handovered); // Set date_handovered
//                 } else {
//                     throw new Error('Access token not found');
//                 }
//             } catch (error) {
//                 console.error('There was a problem with the fetch operation:', error);
//             }
//         };

//         fetchData();
//     }, []);

//     const openModal = (task) => {
//         setSelectedTask(task);
//         setSelectedTaskName(task.name); // Store the selected task name
//         setIsModalOpen(true);
//         if (task.name === "Induction") {
//             setTargetDays('1');
//         } else if (task.name === "Smooth handover Process") {
//             setTargetDays('3');
//         }
//     };

//     const closeModal = () => {
//         setIsModalOpen(false);
//         setSelectedTask(null); // Reset selected task
//         setInputValues({ value: '' }); // Reset input values
//     };

//     const handleSubmit = async (e) => {
//         e.preventDefault();
//         try {
//             let postData = {
//                 name: selectedTask.id,
//                 value: inputValues.value,
//                 targetdays: targetDays,
//             };

//             if (selectedTask.name === "Induction") {
//                 postData = {
//                     ...postData,
//                     ename,
//                     date_joined: dateJoined,
//                     date_handovered: dateHandovered,
//                 };
//                 // Post data to Induction endpoint
//                 const response = await axios.post(
//                     'https://scoreboard.backenddd.in/Docladder/CreateTargetsOfTasks/',
//                     postData
//                 );

//                 if (response.status === 200) {
//                     console.log('Induction task saved successfully');
//                     closeModal();
//                 } else {
//                     throw new Error('Failed to save Induction task');
//                 }
//             } else if (selectedTask.name === "New Employee Training") {
//                 postData = {
//                     ...postData,
//                     topic,
//                     users: selectedUsers,
//                     task: selectedTask.id,

//                     tdate: tdate,
//                 };
//                 // Post data to New Employee Training endpoint
//                 const response = await axios.post(
//                     'https://scoreboard.backenddd.in/Docladder/CreateTargetsOfTasks/',
//                     postData
//                 );

//                 if (response.status === 200) {
//                     console.log('New Employee Training saved successfully');
//                     closeModal();
//                 } else {
//                     throw new Error('Failed to save New Employee Training');
//                 }
//             }
//             else if (selectedTask.name === "Old Emplyees Training") {
//                 postData = {
//                     ...postData,
//                     old_emp_training_topic,
//                     users: selectedUsers,
//                     oldtask: selectedTask.id,
//                     old_t_date: tdate,
//                 };
//                 // Post data to New Employee Training endpoint
//                 const response = await axios.post(
//                     'https://scoreboard.backenddd.in/Docladder/CreateTargetsOfTasks/',
//                     postData
//                 );

//                 if (response.status === 200) {
//                     console.log('New Employee Training saved successfully');
//                     closeModal();
//                 } else {
//                     throw new Error('Failed to save New Employee Training');
//                 }
//             }
//             else {
//                 const response = await axios.post(
//                     'https://scoreboard.backenddd.in/Docladder/CreateTargetsOfTasks/',
//                     postData
//                 );

//                 if (response.status === 200) {
//                     console.log('Task saved successfully');
//                     closeModal();
//                 } else {
//                     throw new Error('Failed to save task');
//                 }
//             }
//         } catch (error) {
//             console.error('Error ', error);
//         }
//     };


//     const handleUserSelect = (e) => {
//         setSelectedUsers(Array.from(e.target.selectedOptions, option => option.value));
//     };


//     return (
//         <>
//             <section>
//                 <div className="container-fluid memberViewContainer">
//                     {data && (
//                         <div className="memberdetailcontainer">
//                             <div className="row">
//                                 <div className="col-3">
//                                     <img className="w-auto align-horizontal" style={{ height: "100px" }} src={`https://scoreboard.backenddd.in${data.image}`} alt="" />
//                                 </div>
//                                 <div className="col-4">
//                                     <div className="userViewText">
//                                         <h3 style={{ color: "black" }}>{data.name}</h3>
//                                         <span>{data.dept} <Link className="signOutLink" to={{ pathname: '/' }} onClick={handleSignOut}><i class="fa-solid fa-right-from-bracket"></i></Link></span>
//                                     </div>
//                                     

//                                 </div>

//                                 <div className="col-5 ">
//                                     <div className="userViewProgress">
//                                         <div className="userViewProgText"></div>
//                                         <div className="userViewProgessBar">
//                                             <p className="mx-1 my-1 text-center">
//                                                 <div className={`progress-bar userViewProgDiv ${data.currentpercentage < 50 ? "userViewProgRed" : data.currentpercentage < 80 ? "userViewProgYellow" : "userViewProgGreen"}`} style={{ width: `${data.currentpercentage}%` }}>
//                                                     {data.currentpercentage === 50 ? <span style={{ marginLeft: "-15px" }}>{data.currentpercentage}%</span> : data.currentpercentage + "%"}
//                                                 </div>
//                                             </p>
//                                         </div>
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                     )}
//                     <table className="myTable min-w-full mt-3 bg-white" style={{ width: "95%", margin: "auto" }}>
//                         <thead>
//                             <tr>
//                                 <th scope="col">Task Name</th>
//                                 <th scope="col" className="imageWidth">Target</th>
//                                 <th scope="col" className="nameWidth">Current Value</th>
//                                 <th scope="col" className="percentageWidth">Point</th>
//                                 <th scope="col" className="percentageWidth">Action</th>
//                             </tr>
//                         </thead>
//                         <tbody>
//                             {targets ? (
//                                 <>
//                                     {targets && targets.map((target, index) => (
//                                         <tr key={index}>
//                                             <td className="py-2 px-4 border-b text-center">{target.name}</td>
//                                             <td className="py-2 px-4 border-b text-center">{target.target}</td>
//                                             {tasks && tasks.map((task) => {
//                                                 if (target.id === task.name) {
//                                                     return (
//                                                         <React.Fragment key={task.name}>
//                                                             <td className="py-2 px-4 border-b text-center">{task.total_taskinput}</td>
//                                                             <td className="py-2 px-4 border-b text-center">{task.total_points}</td>
//                                                         </React.Fragment>
//                                                     );
//                                                 }
//                                                 return null;
//                                             })}
//                                             <td className="py-2 px-4 border-b text-center">
//                                                 <button className='memberTaskBtn' onClick={() => openModal(target)}><i className="fa-solid fa-check"></i> Complete</button>
//                                             </td>
//                                         </tr>
//                                     ))}
//                                 </>
//                             ) : (
//                                 <tr>
//                                     <td colSpan="3">Loading...</td>
//                                 </tr>
//                             )}
//                         </tbody>
//                     </table>

//                     {isModalOpen && (
//                         <div className="modal memberTaskModel">
//                             <div className="modal-content">
//                                 <h2 >Complete Task</h2>
//                                 <form onSubmit={handleSubmit}>
//                                     <label className="form-label">Task Name:</label>
//                                     <input
//                                         type="text"
//                                         value={selectedTask ? selectedTask.name : ''}
//                                         readOnly
//                                     />



//                                     <label>Value:</label>
//                                     <input
//                                         type="text"
//                                         value={inputValues.value}
//                                         onChange={(e) => setInputValues({ value: e.target.value })}
//                                     />
//                                     {selectedTask.name === "Induction" && (
//                                         <>
//                                             <label>Name:</label>
//                                             {/* <input type="text" value={ename} onChange={(e) => setInputValues({ ...inputValues, ename: e.target.value })} id='ename' /> */}
//                                             <input type="text" value={ename} onChange={(e) => setEname(e.target.value)} id='ename' />


//                                             <label>target Days:</label>
//                                             {/* <input type="text" value={targetDays} readOnly onChange={(e) => setInputValues({ ...inputValues, targetDays: e.target.value })} id='targetdays' /> */}
//                                             <input type="text" value={targetDays} readOnly onChange={(e) => setTargetDays(e.target.value)} id='targetdays' />


//                                             <label>Date Joined:</label>
//                                             {/* <input type="text" value={dateJoined} onChange={(e) => setInputValues({ ...inputValues, dateJoined: e.target.value })} id='dateJoined' /> */}
//                                             <input type="text" value={dateJoined} onChange={(e) => setDateJoined(e.target.value)} id='dateJoined' />


//                                             <label>Date Of Handover:</label>
//                                             {/* <input type="text" value={dateHandovered} onChange={(e) => setInputValues({ ...inputValues, dateHandovered: e.target.value })} id='dateHandovered' /> */}
//                                             <input type="text" value={dateHandovered} onChange={(e) => setDateHandovered(e.target.value)} id='dateHandovered' />

//                                         </>
//                                     )}
//                                     {selectedTask.name === "New Employee Training" && (
//                                         <>
//                                             <label>Topic Of Training</label>
//                                             {/* <input type="text" value={targetDays} readOnly onChange={(e) => setInputValues({ ...inputValues, targetDays: e.target.value })} id='targetdays' /> */}
//                                             <input type="text" value={topic} onChange={(e) => setTopic(e.target.value)} id='topic' />


//                                             <select multiple id="users" value={selectedUsers} onChange={handleUserSelect}>
//                                                 {users.map(user => (
//                                                     <option key={user.id} value={user.id}>{user.username}</option>
//                                                 ))}
//                                             </select>


//                                             <label>Date Of Training:</label>
//                                             {/* <input type="text" value={dateJoined} onChange={(e) => setInputValues({ ...inputValues, dateJoined: e.target.value })} id='dateJoined' /> */}
//                                             <input type="date" value={tdate} onChange={(e) => setTdate(e.target.value)} id='dateJoined' />

//                                         </>
//                                     )}
//                                     {selectedTask.name === "Old Emplyees Training" && (
//                                         <>


//                                             <label>Topic Of Training</label>

//                                             {/* <input type="text" value={targetDays} readOnly onChange={(e) => setInputValues({ ...inputValues, targetDays: e.target.value })} id='targetdays' /> */}
//                                             <input type="text" value={old_emp_training_topic} onChange={(e) => setold_emp_training_topic(e.target.value)} id='old_emp_training_topic' />


//                                             <select multiple id="users" value={selectedUsers} onChange={handleUserSelect}>
//                                                 {users.map(user => (
//                                                     <option key={user.id} value={user.id}>{user.username}</option>
//                                                 ))}
//                                             </select>


//                                             <label>Date Of Training:</label>
//                                             {/* <input type="text" value={dateJoined} onChange={(e) => setInputValues({ ...inputValues, dateJoined: e.target.value })} id='dateJoined' /> */}
//                                             <input type="date" value={tdate} onChange={(e) => setTdate(e.target.value)} id='dateJoined' />

//                                         </>
//                                     )}
//                                     <button type="submit" className='saveBtn'>Save</button>
//                                     <button type="button" className='cancelBtn' onClick={closeModal}>Cancel</button>
//                                 </form>
//                             </div>
//                         </div>
//                     )}
//                 </div>
//             </section>
//         </>
//     );
// }

// export default UserWiseTeammember;



import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import axios from 'axios';
import Slider from 'react-slick';
// import { useLocation } from 'react-router-dom';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Link } from "react-router-dom";
// import { useNavigate } from "react-routerom";
import Announcementslider from "./../Announcementslider";


function UserWiseTeamMember(prop) {
    const [data, setData] = useState(null);
    const location = useLocation();
    const { state } = location;
    const [userPermissions, setUserPermissions] = useState([]);

    const stateValue = location.state && location.state['userPermissions'];

    const [monthlyRecords, setMonthlyRecords] = useState([]);
    const [pipelineAndTurnedUp, setPipelineAndTurnedUp] = useState('null');
    const [name, setName] = useState('null');
    const [image, setImage] = useState('null');
    const [username, setUsername] = useState('null');
    const [id, setId] = useState('null');

    const [showModal, setShowModal] = useState(false);
    const [selectedRecord, setSelectedRecord] = useState(null);

    const navigate = useNavigate();

    const handleSignOut = () => {

        // For illustration purposes, let's clear the access token from localStorage
        localStorage.removeItem("access_token");

        // Redirect to the home page
        navigate('/');
    };

    useEffect(() => {
        if (localStorage.getItem('access_token') !== null) {
            fetch('https://scoreboard.backenddd.in/Docladder/GetUserDatafromUsername/', {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('access_token')}`,
                },
            })
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }
                    return response.json();
                })
                .then(data => {
                    setId(data.records.id); // Update state with fetched data
                    // Add this line to inspect the fetched data
                    setData(data.records); // Update state with fetched data
                    setName(data.records.name); // Update state with fetched data
                    setImage(data.records.image); // Update state with fetched data
                    setUsername(data.records.username); // Update state with fetched data

                    setMonthlyRecords(data.monthly_records); // Update state with fetched monthly records
                    setPipelineAndTurnedUp(data.pipelineandturnedup[0].pipeline);
                })
                .catch(error => {
                    console.error('There was a problem with the fetch operation:', error);
                });
        }

        if (state && state.userPermissions) {
            // Print the value to the console
            setUserPermissions(state.userPermissions)
        }
    }, []);


    const openModal = (record) => {
        setSelectedRecord(record);
        setShowModal(true);
    };

    const closeModal = () => {
        setShowModal(false);
        setSelectedRecord(null);
    };


    const handleUpdate = () => {
        if (selectedRecord) {
            const formData = new FormData();
            formData.append('id', id);
            formData.append('name', document.getElementById('recordName').value);
            formData.append('resume_forwarded', document.getElementById('forwarded').value);
            formData.append('pipline', document.getElementById('pipline').value);
            formData.append('turned_up', document.getElementById('turned_up').value);
            formData.append('selected', document.getElementById('selected').value);
            formData.append('revenue', document.getElementById('revenue').value);

            formData.append('target', document.getElementById('target').value);
            // Handle file upload, if available
            const imageInput = document.getElementById('image');
            if (imageInput.files.length > 0) {
                formData.append('image', imageInput.files[0]);
            }

            fetch('https://scoreboard.backenddd.in/Docladder/UpdateRecord/', {
                method: 'PUT',
                body: formData,
            })
                .then((response) => {
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }
                    return response.json();
                })
                .then((data) => {
                    closeModal();
                    // Re-fetch all records after successful update
                    fetch('https://scoreboard.backenddd.in/Docladder/GetUserDatafromUsername/', {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
                        },
                    })
                        .then(response => {
                            if (!response.ok) {
                                throw new Error('Network response was not ok');
                            }
                            return response.json();
                        })
                        .then(data => {
                            // setData(data.records); // Update state with fetched data
                            fetch('https://scoreboard.backenddd.in/Docladder/GetUserDatafromUsername/', {
                                headers: {
                                    Authorization: `Bearer ${localStorage.getItem('access_token')}`,
                                },
                            })
                                .then(response => {
                                    if (!response.ok) {
                                        throw new Error('Network response was not ok');
                                    }
                                    return response.json();
                                })
                                .then(data => {
                                    setId(data.records.id); // Update state with fetched data
                                    setData(data.records); // Update state with fetched data
                                    setName(data.records.name); // Update state with fetched data
                                    setImage(data.records.image); // Update state with fetched data
                                    setUsername(data.records.username); // Update state with fetched data

                                    setMonthlyRecords(data.monthly_records); // Update state with fetched monthly records
                                    setPipelineAndTurnedUp(data.pipelineandturnedup[0].pipeline);
                                })
                                .catch(error => {
                                    console.error('There was a problem with the fetch operation:', error);
                                });
                        })
                        .catch(error => {
                            console.error('There was a problem with the fetch operation:', error);
                        });
                })
                .catch((error) => {
                    console.error('There was a problem with the fetch operation:', error);
                    // Handle errors here
                });
        }
    };


    return (
        <>
            <section>
                <nav class="navbar navbar-expand-lg navbar-light bg-light">
                    {userPermissions && userPermissions == "teamleader and docladdermember" && (
                        <Link
                            to="/teamleaderview"
                            state={{ userPermissions: "teamleader and docladdermember" }}
                            style={{ color: "black" }}
                        >
                            Switch To Leader's Page
                        </Link>
                    )}

                    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span class="navbar-toggler-icon"></span>
                    </button>

                    {userPermissions && userPermissions == "custom_user_view and center_manager_view" && (
                        <Link to="/teamleaderview" className="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent">Center Manager</Link>
                    )}

                    {/* {userPermissions && userPermissions == "custom_user_view and center_manager_view" && (
            <Link to="/teamrecord" className="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent" >Team Records</Link>
          )} */}

                    <Link
                        className="signOutLink"
                        to={{ pathname: '/' }}
                        onClick={handleSignOut}
                    >
                        <i class="fa-solid fa-right-from-bracket"></i>
                    </Link>

                    {/*  */}
                </nav>
                <div>
                    <div>
                        <div>
                            <div>

                                {/****************************** Viewer Code (Someone) ******************************/}

                                <table className="min-w-full bg-white border border-gray-300" style={{ width: "95%", margin: "auto" }}>
                                    <thead>
                                        <tr>
                                            <th scope="col" className="py-2 px-4 border-b">HR</th>
                                            <th scope="col" className="py-2 px-4 border-b">Name</th>
                                            <th scope="col" className="py-2 px-4 border-b">Forwarded</th>
                                            <th scope="col" className="py-2 px-4 border-b">Pipeline</th>
                                            <th scope="col" className="py-2 px-4 border-b">Turned Up</th>
                                            <th scope="col" className="py-2 px-4 border-b">Selected</th>
                                            <th scope="col" className="py-2 px-4 border-b">Revenue</th>
                                            <th scope="col" className="py-2 px-4 border-b">Options</th>
                                        </tr>
                                    </thead>
                                    <tbody className="divide-y divide-gray-300">
                                        {/* {data && data.map((record) => ( */}
                                        {data && monthlyRecords.map((record, index) => {

                                            return (
                                                <tr key={record.id}>
                                                    <td className="py-2 px-4 border-b">
                                                        <img src={`https://scoreboard.backenddd.in${image}`} className="w-auto" style={{ height: "100px" }} alt="" />
                                                    </td>
                                                    <td className="py-2 px-4 border-b text-center">{name}</td>

                                                    {/* Resume forwarded */}
                                                    <td className="py-2 px-4 border-b text-center">{record.resume_forwarded}</td>
                                                    {/* Pipeline */}
                                                    <td className="py-2 px-4 border-b text-center">{pipelineAndTurnedUp}</td>
                                                    {/* Turned Up */}
                                                    <td className="py-2 px-4 border-b text-center">{record.turned_up}</td>
                                                    {/* Selected */}
                                                    <td className="py-2 px-4 border-b text-center">{record.selected}</td>
                                                    <td className="py-2 px-4 border-b text-center">{isNaN(record.revenue) ? 0 : parseFloat(record.revenue)} lac</td>

                                                    <button className="btn btn-primary" onClick={() => openModal(record)}>
                                                        Edit
                                                    </button>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>

                <Announcementslider />
            </section>


            {showModal && (
                <div className="modal" tabIndex="-1" role="dialog" style={{ display: 'block' }}>
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Edit Record</h5>
                                <button type="button" className="btn-close" onClick={closeModal}></button>
                            </div>
                            <div className="modal-body">
                                {selectedRecord && (
                                    <form>
                                        <input type="hidden" className="form-control" id="recordId" value={selectedRecord.id} />

                                        <div className="mb-3">
                                            <label htmlFor="recordName" className="form-label">Record Name</label>
                                            <input type="text" className="form-control" id="recordName" value={name} />
                                        </div>

                                        <div className="mb-3">
                                            <label htmlFor="forwarded" className="form-label">Resume Forwarded</label>
                                            <input type="number" className="form-control" id="forwarded" placeholder={selectedRecord.resume_forwarded} onChange={(e) => setSelectedRecord({ ...selectedRecord, resume_forwarded: e.target.value })} />
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="pipline" className="form-label">Pipeline</label>
                                            <input type="number" className="form-control" id="pipline" placeholder={selectedRecord.pipline} onChange={(e) => setSelectedRecord({ ...selectedRecord, pipline: e.target.value })} />
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="turned_up" className="form-label">Turned Up</label>
                                            <input type="number" className="form-control" id="turned_up" placeholder={selectedRecord.turned_up} onChange={(e) => setSelectedRecord({ ...selectedRecord, turned_up: e.target.value })} />
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="selected" className="form-label">Selected</label>
                                            <input type="number" className="form-control" id="selected" placeholder={selectedRecord.selected} onChange={(e) => setSelectedRecord({ ...selectedRecord, selected: e.target.value })} />
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="selected" className="form-label">Revenue</label>
                                            <input type="number" className="form-control" id="revenue" placeholder={selectedRecord.revenue} onChange={(e) => setSelectedRecord({ ...selectedRecord, revenue: e.target.value })} />
                                        </div>
                                        <div className="mb-3" style={{ display: "none" }}>
                                            <label htmlFor="target" className="form-label">Target</label>
                                            <input type="number" className="form-control" id="target" value="0" readOnly />
                                        </div>
                                        <div className="mb-3" style={{ display: "none" }}>
                                            <label htmlFor="image" className="form-label">Image URL</label>

                                            <input type="file" className="form-control" id="image" onChange={(e) => setSelectedRecord({ ...selectedRecord, image: e.target.value })} />
                                        </div>
                                        <div className="btncontainer">
                                            <button type="button" className="btn btn-primary m-2" onClick={handleUpdate}>
                                                Save Changes
                                            </button>
                                            {/* <button className="btn btn-danger m-2" onClick={(e) => handleDelete(selectedRecord.id, e)}>
                        Delete
                      </button> */}
                                            <button type="button" className="btn btn-secondary m-2" onClick={closeModal}>
                                                Close
                                            </button>
                                        </div>
                                    </form>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            )
            }

        </>
    );
}

export default UserWiseTeamMember;
