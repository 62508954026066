import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Navbar from './../NavBar'
import { Link } from "react-router-dom";


function ShowMarkettingList({ prop }) {
    const location = useLocation();
    const stateValue = location.state && location.state['newUser'];

    console.log("State Value", stateValue)

    const [data, setData] = useState(null);
    const [userPermissions, setUserPermissions] = useState([]);

    const [isAuth, setIsAuth] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [selectedRecord, setSelectedRecord] = useState(null);
    const [isSuperuser, setIsSuperuser] = useState(false); // State to store superuser status
    const navigate = useNavigate();
    const [markettingTypes, setMarkettingTypes] = useState([]);
    const [tddE2E, settddE2E] = useState(null);
    


    useEffect(() => {
        if (localStorage.getItem("access_token") !== null) {
            setIsAuth(true); // Set authentication status to true if access token is present
            fetch("https://scoreboard.backenddd.in/marketting-types/", {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                },
            })
                .then((response) => {
                    if (!response.ok) {
                        throw new Error("Network response was not ok");
                    }
                    return response.json();
                })
                .then((data) => {
                    setData(data || []); // Update state with fetched data
                    console.log("Data: ", data)
                    settddE2E(data[0].ddE2E)
                    console.log(data[0].ddE2E)
                    setIsSuperuser(data.is_superuser);
                    setUserPermissions(data.user_permissions || []);

                    console.log("User Permission: ", data.user_permissions);

                })
                .catch((error) => {
                    console.error("There was a problem with the fetch operation:", error);
                });
        }
    }, []);

    const openModal = (record) => {
        setSelectedRecord(record);
        setShowModal(true);
    };

    const closeModal = () => {
        setShowModal(false);
        setSelectedRecord(null);
    };

    useEffect(() => {
        // Fetch the marketting types from your Django backend
        fetch('https://scoreboard.backenddd.in/marketting-types/')
            .then(response => response.json())
            .then(data => setMarkettingTypes(data))
            .catch(error => console.error('Error fetching marketting types:', error));
    }, []);


    const handleUpdate = (e) => {
        e.preventDefault();

        if (selectedRecord) {
            const formData = new FormData();
            formData.append("id", selectedRecord.id);
            formData.append("name", document.getElementById("name").value);
            formData.append("total_prefered_location", document.getElementById("total_prefered_location").value);
            formData.append("ddE2E", document.getElementById("ddE2E").value);
            formData.append("DDclient", document.getElementById("DDclient").value);
            formData.append("dailyVisit", document.getElementById("dailyVisit").value);
            formData.append("docladerId", document.getElementById("docladerId").value);
            formData.append("pace", document.getElementById("pace").value);

            // Adjust other fields based on your Marketting model


            fetch(`https://scoreboard.backenddd.in/typeofupdatemarketting/`, {
                method: "PUT",
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                },
                body: formData,
            })
                .then((response) => {
                    if (!response.ok) {
                        throw new Error("Network response was not ok");
                    }
                    return response.json();
                })
                .then((data) => {
                    console.log("Updated record:", data);
                    closeModal();
                    // Fetch updated records or update state as needed

                    fetch("https://scoreboard.backenddd.in/marketting-types/", {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                        },
                    })
                        .then((response) => {
                            if (!response.ok) {
                                throw new Error("Network response was not ok");
                            }
                            return response.json();
                        })
                        .then((data) => {
                            setData(data || []); // Update state with fetched data
                            setIsSuperuser(data.is_superuser);
                            setUserPermissions(data.user_permissions || []);

                            console.log("User Permission: ", data.user_permissions);

                        })
                        .catch((error) => {
                            console.error("There was a problem with the fetch operation:", error);
                        });
                })
                .catch((error) => {
                    console.error("There was a problem with the fetch operation:", error);
                });
        }
    };

    const handleDelete = (id, e) => {
        e.preventDefault();
        const isConfirmed = window.confirm(
            "Are you sure you want to delete this record?"
        );

        if (isConfirmed) {
            fetch(`https://scoreboard.backenddd.in/DelRecord/${id}/`, {
                method: "DELETE",
            })
                .then((response) => {
                    if (!response.ok) {
                        throw new Error("Network response was not ok");
                    }
                    console.log("Deleted Successfully");
                })
                .then((data) => {
                    console.log("Deleted record:", data);
                    closeModal();

                    fetch("https://scoreboard.backenddd.in/GetAllRecord/", {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                        },
                    })
                        .then((response) => {
                            if (!response.ok) {
                                throw new Error("Network response was not ok");
                            }
                            return response.json();
                        })
                        .then((data) => {
                            setData(data.records || []);
                            setIsSuperuser(data.is_superuser);
                        })
                        .catch((error) => {
                            console.error(
                                "There was a problem with the fetch operation:",
                                error
                            );
                        });
                })
                .catch((error) => {
                    console.error("There was a problem with the fetch operation:", error);
                });
        }
    };

    // Announcements
    const [announcements, setAnnouncements] = useState([]);

    useEffect(() => {
        const fetchAnnouncements = async () => {
            try {
                const response = await axios.get(
                    "https://scoreboard.backenddd.in/ShowAnnouncements/"
                );
                setAnnouncements(response.data);
            } catch (error) {
                console.error("Error fetching announcements:", error);
            }
        };

        fetchAnnouncements();
    }, []);
    // End Announcements Page

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5000,
        prevArrow: <></>, // Hide the previous arrow
        nextArrow: <></>, // Hide the next arrow
    };

    return (
        <>
            <section>
                <Navbar />
                <div>
                    <div>
                        <div>
                            <div>
    
                                {/****************************** Viewer Code (Someone) ******************************/}


                                <table class="myTable min-w-full bg-white" style={{ width: "95%", margin: "auto" }}>
                                    <thead>
                                        <tr>
                                            {/* <th scope="col">Rank</th>  */}

                                            <th scope="col" class="nameWidth">
                                                Name
                                            </th>
                                            <th scope="col" class="resumeWidth">
                                                Prefered Loc
                                            </th>
                                            <th scope="col" class="selWidth">
                                                Pace
                                            </th>
                                          
                                            <th scope="col" class="pipWidth">
                                                ddE2E
                                            </th>
                                      
                                            <th scope="col" class="turWidth">
                                                DDclient
                                            </th>
                                            <th scope="col" class="selWidth">
                                                dailyVisit
                                            </th>
                                            <th scope="col" class="selWidth">
                                                docladerId
                                            </th>
                                            <th className="col">Options</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {data &&
                                            data.map((record) => (
                                                <tr key={record.id}>

                                                    <td className="py-2 px-4 border-b text-center">{record.name}</td>
                                                    <td className="py-2 px-4 border-b text-center">{record.total_prefered_location}</td>
                                                    <td className="py-2 px-4 border-b text-center">{record.pace}</td>
                                                    {/* <td className="py-2 px-4 border-b text-center">{record.ddE2E}</td> */}
                                                    {record.ddE2E !== null && record.ddE2E !== 0 ? (
                                                        <td className="py-2 px-4 border-b text-center">{record.ddE2E}</td>
                                                    ) : <td className="py-2 px-4 border-b text-center"></td>}
                                                    <td className="py-2 px-4 border-b text-center">{record.DDclient}</td>
                                                    <td className="py-2 px-4 border-b text-center">{record.dailyVisit}</td>
                                                    <td className="py-2 px-4 border-b text-center">{record.docladerId}</td>


                                                    <td className="py-2 px-4 border-b text-center">
                                                        <button className="btn btn-primary" onClick={() => openModal(record)}>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-pencil" viewBox="0 0 16 16">
                                                                <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325" />
                                                            </svg>
                                                        </button>
                                                    </td>
                                                </tr>

                                            ))}

                                    </tbody>
                                    <Link to="/createtargetformarketting">
                                        <button className="fixed bottom-5 left-8 bg-blue-700 text-white p-4 rounded-full">
                                            +
                                        </button>
                                    </Link>
                                </table>

                            </div>
                        </div>
                    </div>
                </div>

                <div className="recentContainer">
                    <div className="row recentNews">
                        <div className="col-3">
                            <h4>Recent Event</h4>
                        </div>
                        <div className="col-9">
                            <Slider {...settings}>
                                {announcements.map((announcement) => (
                                    <div key={announcement.id}>
                                        <span style={{ fontSize: "1.2rem" }}>
                                            {announcement.name}
                                        </span>
                                    </div>
                                ))}
                            </Slider>
                        </div>
                    </div>
                </div>
            </section>
            {showModal && (
                <div className="modal" tabIndex="-1" role="dialog" style={{ display: "block" }}>
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Edit Marketting Record</h5>
                                <button type="button" className="btn-close" onClick={closeModal}></button>
                            </div>
                            <div className="modal-body">
                                {selectedRecord && (
                                    <form onSubmit={handleUpdate}>
                                        <input type="hidden" className="form-control" id="recordId" placeholder={selectedRecord.id} />

                                        <div className="mb-3">
                                            <label htmlFor="recordName" className="form-label">
                                                Name
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="name"
                                                value={selectedRecord.name}
                                                // onChange={(e) =>
                                                //     setSelectedRecord({
                                                //         ...selectedRecord,
                                                //         name: e.target.value,
                                                //     })
                                                // }
                                                readonly
                                            />
                                        </div>

                                        <div className="mb-3">
                                            <label htmlFor="total_prefered_location" className="form-label">
                                                Total Prefered Location
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="total_prefered_location"
                                                placeholder={selectedRecord.total_prefered_location}
                                                onChange={(e) =>
                                                    setSelectedRecord({
                                                        ...selectedRecord,
                                                        total_prefered_location: e.target.value,
                                                    })
                                                }
                                            />
                                        </div>

                                        {tddE2E !== 0 && selectedRecord.ddE2E !== null ? (
                                            <div className="mb-3">
                                                <label htmlFor="ddE2E" className="form-label">
                                                    Docladder E2E
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="ddE2E"
                                                    placeholder={selectedRecord.ddE2E}
                                                    onChange={(e) =>
                                                        setSelectedRecord({
                                                            ...selectedRecord,
                                                            ddE2E: e.target.value,
                                                        })
                                                    }
                                                />
                                            </div>
                                        ) : <input
                                            type="hidden"
                                            className="form-control"
                                            id="ddE2E"
                                            placeholder={selectedRecord.ddE2E}
                                            onChange={(e) =>
                                                setSelectedRecord({
                                                    ...selectedRecord,
                                                    ddE2E: e.target.value,
                                                })
                                            }
                                        />}

                                        <div className="mb-3">
                                            <label htmlFor="DDclient" className="form-label">
                                                DD Client
                                            </label>
                                            <input
                                                type="number"
                                                className="form-control"
                                                id="DDclient"
                                                placeholder={selectedRecord.DDclient}
                                                onChange={(e) =>
                                                    setSelectedRecord({
                                                        ...selectedRecord,
                                                        DDclient: e.target.value,
                                                    })
                                                }
                                            />
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="dailyVisit" className="form-label">
                                                Daily Visit
                                            </label>
                                            <input
                                                type="number"
                                                className="form-control"
                                                id="dailyVisit"
                                                placeholder={selectedRecord.dailyVisit}
                                                onChange={(e) =>
                                                    setSelectedRecord({
                                                        ...selectedRecord,
                                                        dailyVisit: e.target.value,
                                                    })
                                                }
                                            />
                                        </div>

                                        <div className="mb-3">
                                            <label htmlFor="docladerId" className="form-label">
                                                Docladder Id
                                            </label>
                                            <input
                                                type="number"
                                                className="form-control"
                                                id="docladerId"
                                                placeholder={selectedRecord.docladerId}
                                                onChange={(e) =>
                                                    setSelectedRecord({
                                                        ...selectedRecord,
                                                        docladerId: e.target.value,
                                                    })
                                                }
                                            />
                                        </div>

                                        <div className="mb-3">
                                            <label htmlFor="pace" className="form-label">
                                                Pace
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="pace"
                                                placeholder={selectedRecord.pace}
                                                onChange={(e) =>
                                                    setSelectedRecord({
                                                        ...selectedRecord,
                                                        pace: e.target.value,
                                                    })
                                                }
                                            />
                                        </div>

                                        {/* Repeat similar blocks for other fields */}
                                        <div className="btncontainer">
                                            <button type="submit" className="btn btn-primary m-2">
                                                Save Changes
                                            </button>
                                            <button type="button" className="btn btn-secondary m-2" onClick={closeModal}>
                                                Close
                                            </button>
                                        </div>
                                    </form>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}
export default ShowMarkettingList;
