import React, { useState, useEffect } from "react";
import axios from "axios";
import Navbar from "../NavBar";
import { Link } from 'react-router-dom'

const PartnerProgramTable = () => {
  const [files, setFiles] = useState([]);
  const [fileTypes, setFileTypes] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [fileDetails, setFileDetails] = useState({
    filetype: null,
    name: "",
    docs: null,
    valid_date: "",
    rate: "",
  });
  const [editFileId, setEditFileId] = useState(null);
  const [filteredFiles, setFilteredFiles] = useState([]);
  const [expandedRow, setExpandedRow] = useState(null);

  useEffect(() => {
    fetchAllFiles();
    fetchFileTypes();
  }, []);

  const fetchAllFiles = async () => {
    try {
      const response = await axios.get(
        "https://scoreboard.backenddd.in/PartnerFiles/partners-files/",
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        }
      );
      setFiles(response.data);
      setFilteredFiles(response.data);
    } catch (error) {
      console.error("Error fetching files:", error);
    }
  };

  const fetchFileTypes = async () => {
    try {
      const response = await axios.get(
        "https://scoreboard.backenddd.in/PartnerFiles/filetypes/"
      );
      setFileTypes(response.data);
    } catch (error) {
      console.error("Error fetching file types:", error);
    }
  };

  const handleDeleteFile = async (id) => {
    try {
      await axios.delete(
        `https://scoreboard.backenddd.in/PartnerFiles/partners-files/${id}/`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        }
      );
      fetchAllFiles();
    } catch (error) {
      console.error("Error deleting file:", error);
    }
  };

  const handleFormChange = (e) => {
    const { name, value, files } = e.target;
    if (name === "docs") {
      setFileDetails((prev) => ({ ...prev, docs: files[0] }));
    } else {
      setFileDetails((prev) => ({ ...prev, [name]: value }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    Object.entries(fileDetails).forEach(([key, value]) => {
      if (value) formData.append(key, value);
    });

    const url = editFileId
      ? `https://scoreboard.backenddd.in/PartnerFiles/partners-files/${editFileId}/`
      : "https://scoreboard.backenddd.in/PartnerFiles/partners-files/";
    const method = editFileId ? "put" : "post";

    try {
      await axios({
        method,
        url,
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });
      fetchAllFiles();
      setModalOpen(false);
      resetForm();
    } catch (error) {
      console.error("Error saving file:", error);
    }
  };

  const resetForm = () => {
    setFileDetails({
      filetype: "",
      name: "",
      docs: null,
      valid_date: "",
      rate: "",
    });
    setEditFileId(null);
  };

  const handleEdit = (file) => {
    setFileDetails({
      filetype: file.filetype ? String(file.filetype) : "", // Convert to string
      name: file.name,
      docs: null,
      valid_date: file.valid_date,
      rate: file.rate,
    });
    setEditFileId(file.id);
    setModalOpen(true);
  };

  const toggleRowExpansion = (index) => {
    setExpandedRow(expandedRow === index ? null : index);
  };

  const onClose = () => {
    setModalOpen(false);
    resetForm();
  };

  return (
    <div className="font-sans">
      <Navbar />
      <div className="container mt-2 flex justify-between">
        <h1 className="text-3xl text-blue-800">Partner Program Files</h1>
        <button
          className="bg-blue-800 text-white px-4 py-2 rounded hover:bg-blue-900"
          onClick={() => setModalOpen(true)}
        >
          Add Partner Files
        </button>
        <Link
          to="/fileType"
          className="bg-blue-800 text-white px-4 py-2 rounded hover:bg-blue-600"
          // state={{ userPermissions: "teamleader and docladdermember" }}
          style={{ color: "black" }}
        >
          Add File-Type
        </Link>
        {/* partnersFiles */}
      </div>

      <table
        className="min-w-full bg-white border mt-4"
        style={{ width: "90%", margin: "auto" }}
      >
        <thead>
          <tr>
            <th className="py-2 px-4">Sr. No</th>
            <th className="py-2 px-4">Name</th>
            <th className="py-2 px-4">File Type</th>

            <th className="py-2 px-4">Created Date</th>

            <th className="py-2 px-4">Valid Date</th>
            <th className="py-2 px-4">Rate</th>
            <th className="py-2 px-4">Actions</th>
          </tr>
        </thead>
        <tbody>
          {filteredFiles.map((file, index) => (
            <React.Fragment key={file.id}>
              <tr onClick={() => toggleRowExpansion(index)}>
                <td className="text-center">{index + 1}</td>
                <td className="text-center">{file.name}</td>
                <td className="text-center">{file.filetype__name}</td>

                <td className="text-center">
                  {new Date(file.uploaded_date).toLocaleDateString("en-GB")}{" "}
                  {new Date(file.uploaded_date).toLocaleTimeString("en-GB")}
                </td>

                <td className="text-center">{file.valid_date}</td>
                <td className="text-center">Rs. {file.rate}</td>

                <td className="py-2 px-4 border-b text-center">
                  <button className="text-sm font-medium text-green-600 hover:text-green-800 ">
                    <a
                      href={`https://scoreboard.backenddd.in${file.docs}/`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-sm font-medium text-blue-600 hover:text-blue-800"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-eye"
                        viewBox="0 0 16 16"
                      >
                        <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8M1.173 8a13 13 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5s3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5s-3.879-1.168-5.168-2.457A13 13 0 0 1 1.172 8z" />
                        <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5M4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0" />
                      </svg>
                    </a>
                  </button>
                  <button
                    onClick={() => handleEdit(file)}
                    className="text-sm font-medium text-green-600 hover:text-green-800 ml-2 px-2"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-pen-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="m13.498.795.149-.149a1.207 1.207 0 1 1 1.707 1.708l-.149.148a1.5 1.5 0 0 1-.059 2.059L4.854 14.854a.5.5 0 0 1-.233.131l-4 1a.5.5 0 0 1-.606-.606l1-4a.5.5 0 0 1 .131-.232l9.642-9.642a.5.5 0 0 0-.642.056L6.854 4.854a.5.5 0 1 1-.708-.708L9.44.854A1.5 1.5 0 0 1 11.5.796a1.5 1.5 0 0 1 1.998-.001" />
                    </svg>
                  </button>
                  <button onClick={() => handleDeleteFile(file.id)}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-trash3-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5m-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5M4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06m6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528M8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5" />
                    </svg>
                  </button>
                </td>
              </tr>
              {/* {expandedRow === index && (
                <tr>
                  <td colSpan="5">Additional Details...</td>
                </tr>
              )} */}
            </React.Fragment>
          ))}
        </tbody>
      </table>

      {modalOpen && (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-60 flex justify-center items-center z-50">
          <div className="bg-white w-3/4 max-w-lg rounded-lg shadow-lg">
            <div className="flex justify-between items-center bg-blue-800 text-white px-2 py-4 rounded-t-lg">
              <h2 className="text-xl font-semibold text-white">
                {editFileId ? "Edit File" : "Add New File"}
              </h2>
              <button
                className="text-white hover:text-gray-300"
                onClick={onClose}
                aria-label="Close modal"
              >
                &times;
              </button>
            </div>

            <form onSubmit={handleSubmit} className="p-4 space-y-2">
              {/* File Type */}
              <div>
                <label
                  htmlFor="filetype"
                  className="block text-sm font-medium text-gray-700"
                >
                  File Type
                </label>
                <select
                  id="filetype"
                  name="filetype"
                  value={fileDetails.filetype}
                  onChange={handleFormChange}
                  required
                  className="w-full mt-1 p-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-600"
                >
                  <option value="">Select Type</option>
                  {fileTypes.map((type) => (
                    <option key={type.id} value={String(type.id)}>
                      {type.name}
                    </option>
                  ))}
                </select>
              </div>

              {/* File Name */}
              <div>
                <label
                  htmlFor="name"
                  className="block text-sm font-medium text-gray-700"
                >
                  File Name
                </label>
                <input
                  id="name"
                  type="text"
                  name="name"
                  placeholder="Enter file name"
                  value={fileDetails.name}
                  onChange={handleFormChange}
                  required
                  className="w-full mt-1 p-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-600"
                />
              </div>

              {/* Valid Date */}
              <div>
                <label
                  htmlFor="valid_date"
                  className="block text-sm font-medium text-gray-700"
                >
                  Valid Date
                </label>
                <input
                  id="valid_date"
                  type="date"
                  name="valid_date"
                  value={fileDetails.valid_date}
                  onChange={handleFormChange}
                  required
                  className="w-full mt-1 p-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-600"
                />
              </div>

              {/* Rate */}
              <div>
                <label
                  htmlFor="rate"
                  className="block text-sm font-medium text-gray-700"
                >
                  Rate
                </label>
                <input
                  id="rate"
                  type="text"
                  name="rate"
                  placeholder="Enter rate"
                  value={fileDetails.rate}
                  onChange={handleFormChange}
                  required
                  className="w-full mt-1 p-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-600"
                />
              </div>

              {/* Document */}
              <div>
                <label
                  htmlFor="docs"
                  className="block text-sm font-medium text-gray-700"
                >
                  Upload Document
                </label>
                <input
                  id="docs"
                  type="file"
                  name="docs"
                  onChange={handleFormChange}
                  className="w-full mt-1 p-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-600"
                />
              </div>

              {/* Action Buttons */}
              <div className="flex justify-end space-x-4">
                <button
                  type="button"
                  onClick={onClose}
                  className="px-4 py-2 text-sm font-medium text-white bg-red-600 rounded hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-400"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="px-4 py-2 text-sm font-medium text-white bg-blue-800 rounded hover:bg-blue-900 focus:outline-none focus:ring-2 focus:ring-blue-400"
                >
                  {editFileId ? "Update" : "Save"}
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default PartnerProgramTable;
