import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import axios from 'axios';
import Slider from 'react-slick';
// import { useLocation } from 'react-router-dom';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Link } from "react-router-dom";
// import { useNavigate } from "react-routerom";
import Announcementslider from "./Announcementslider";


function UserView(prop) {
  const [data, setData] = useState(null);
  const location = useLocation();
  const { state } = location;
  const [userPermissions, setUserPermissions] = useState([]);
  
  const stateValue = location.state && location.state['userPermissions'];

  const [monthlyRecords, setMonthlyRecords] = useState([]);
  const [pipelineAndTurnedUp, setPipelineAndTurnedUp] = useState('null');
  const [name, setName] = useState('null');
  const [image, setImage] = useState('null');
  const [username, setUsername] = useState('null');
  const [id, setId] = useState('null');

  const [showModal, setShowModal] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);

  const navigate = useNavigate();

  const handleSignOut = () => {

    // For illustration purposes, let's clear the access token from localStorage
    localStorage.removeItem("access_token");

    // Redirect to the home page
    navigate('/');
  };

  useEffect(() => {
    if (localStorage.getItem('access_token') !== null) {
      fetch('https://scoreboard.backenddd.in/GetUserDatafromUsername/', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        },
      })
        .then(response => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .then(data => {
          setId(data.records.id); // Update state with fetched data
           // Add this line to inspect the fetched data
          setData(data.records); // Update state with fetched data
          setName(data.records.name); // Update state with fetched data
          setImage(data.records.image); // Update state with fetched data
          setUsername(data.records.username); // Update state with fetched data

          setMonthlyRecords(data.monthly_records); // Update state with fetched monthly records
          setPipelineAndTurnedUp(data.pipelineandturnedup[0].pipeline);
        })
        .catch(error => {
          console.error('There was a problem with the fetch operation:', error);
        });
    }

    if (state && state.userPermissions) {
      // Print the value to the console
      setUserPermissions(state.userPermissions)
    }
  }, []);


  const openModal = (record) => {
    setSelectedRecord(record);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    setSelectedRecord(null);
  };


  const handleUpdate = () => {
    if (selectedRecord) {
      const formData = new FormData();
      formData.append('id', id);
      formData.append('name', document.getElementById('recordName').value);
      formData.append('resume_forwarded', document.getElementById('forwarded').value);
      formData.append('pipline', document.getElementById('pipline').value);
      formData.append('turned_up', document.getElementById('turned_up').value);
      formData.append('selected', document.getElementById('selected').value);
      formData.append('revenue', document.getElementById('revenue').value);

      formData.append('target', document.getElementById('target').value);
      // Handle file upload, if available
      const imageInput = document.getElementById('image');
      if (imageInput.files.length > 0) {
        formData.append('image', imageInput.files[0]);
      }

      fetch('https://scoreboard.backenddd.in/UpdateRecord/', {
        method: 'PUT',
        body: formData,
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .then((data) => {
          closeModal();
          // Re-fetch all records after successful update
          fetch('https://scoreboard.backenddd.in/GetUserDatafromUsername/', {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('access_token')}`,
            },
          })
            .then(response => {
              if (!response.ok) {
                throw new Error('Network response was not ok');
              }
              return response.json();
            })
            .then(data => {
              // setData(data.records); // Update state with fetched data
              fetch('https://scoreboard.backenddd.in/GetUserDatafromUsername/', {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem('access_token')}`,
                },
              })
                .then(response => {
                  if (!response.ok) {
                    throw new Error('Network response was not ok');
                  }
                  return response.json();
                })
                .then(data => {
                  setId(data.records.id); // Update state with fetched data
                  setData(data.records); // Update state with fetched data
                  setName(data.records.name); // Update state with fetched data
                  setImage(data.records.image); // Update state with fetched data
                  setUsername(data.records.username); // Update state with fetched data

                  setMonthlyRecords(data.monthly_records); // Update state with fetched monthly records
                  setPipelineAndTurnedUp(data.pipelineandturnedup[0].pipeline);
                })
                .catch(error => {
                  console.error('There was a problem with the fetch operation:', error);
                });
            })
            .catch(error => {
              console.error('There was a problem with the fetch operation:', error);
            });
        })
        .catch((error) => {
          console.error('There was a problem with the fetch operation:', error);
          // Handle errors here
        });
    }
  };



  return (
    <>
      <section>
        <nav class="navbar navbar-expand-lg navbar-light bg-light">
          <Link to="/training">Training</Link>

          <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
          </button>

          {userPermissions && userPermissions == "custom_user_view and center_manager_view" && (
            <Link to="/teamleaderview" className="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent">Center Manager</Link>
          )}

          {/* {userPermissions && userPermissions == "custom_user_view and center_manager_view" && (
            <Link to="/teamrecord" className="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent" >Team Records</Link>
          )} */}
          
          <Link
            className="signOutLink"
            to={{ pathname: '/' }}
            onClick={handleSignOut}
          >
            <i class="fa-solid fa-right-from-bracket"></i>
          </Link>

          {/*  */}
        </nav>
        <div>
          <div>
            <div>
              <div>

                {/****************************** Viewer Code (Someone) ******************************/}

                <table className="min-w-full bg-white border border-gray-300" style={{ width: "95%", margin: "auto" }}>
                  <thead>
                    <tr>
                      <th scope="col" className="py-2 px-4 border-b">HR</th>
                      <th scope="col" className="py-2 px-4 border-b">Name</th>
                      <th scope="col" className="py-2 px-4 border-b">Forwarded</th>
                      <th scope="col" className="py-2 px-4 border-b">Pipeline</th>
                      <th scope="col" className="py-2 px-4 border-b">Turned Up</th>
                      <th scope="col" className="py-2 px-4 border-b">Selected</th>
                      <th scope="col" className="py-2 px-4 border-b">Revenue</th>
                      <th scope="col" className="py-2 px-4 border-b">Options</th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-300">
                    {/* {data && data.map((record) => ( */}
                    {data && monthlyRecords.map((record, index) => {

                      return (
                        <tr key={record.id}>
                          <td className="py-2 px-4 border-b">
                            <img src={`https://scoreboard.backenddd.in${image}`} className="w-auto" style={{ height: "100px" }} alt="" />
                          </td>
                          <td className="py-2 px-4 border-b text-center">{name}</td>

                          {/* Resume forwarded */}
                          <td className="py-2 px-4 border-b text-center">{record.resume_forwarded}</td>
                          {/* Pipeline */}
                          <td className="py-2 px-4 border-b text-center">{pipelineAndTurnedUp}</td>
                          {/* Turned Up */}
                          <td className="py-2 px-4 border-b text-center">{record.turned_up}</td>
                          {/* Selected */}
                          <td className="py-2 px-4 border-b text-center">{record.selected}</td>
                          <td className="py-2 px-4 border-b text-center">{isNaN(record.revenue) ? 0 : parseFloat(record.revenue)} lac</td>

                          <button className="btn btn-primary" onClick={() => openModal(record)}>
                            Edit
                          </button>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <Announcementslider />
      </section>


      {showModal && (
        <div className="modal" tabIndex="-1" role="dialog" style={{ display: 'block' }}>
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Edit Record</h5>
                <button type="button" className="btn-close" onClick={closeModal}></button>
              </div>
              <div className="modal-body">
                {selectedRecord && (
                  <form>
                    <input type="hidden" className="form-control" id="recordId" value={selectedRecord.id} />

                    <div className="mb-3">
                      <label htmlFor="recordName" className="form-label">Record Name</label>
                      <input type="text" className="form-control" id="recordName" value={name} />
                    </div>

                    <div className="mb-3">
                      <label htmlFor="forwarded" className="form-label">Resume Forwarded</label>
                      <input type="number" className="form-control" id="forwarded" placeholder={selectedRecord.resume_forwarded} onChange={(e) => setSelectedRecord({ ...selectedRecord, resume_forwarded: e.target.value })} />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="pipline" className="form-label">Pipeline</label>
                      <input type="number" className="form-control" id="pipline" placeholder={selectedRecord.pipline} onChange={(e) => setSelectedRecord({ ...selectedRecord, pipline: e.target.value })} />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="turned_up" className="form-label">Turned Up</label>
                      <input type="number" className="form-control" id="turned_up" placeholder={selectedRecord.turned_up} onChange={(e) => setSelectedRecord({ ...selectedRecord, turned_up: e.target.value })} />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="selected" className="form-label">Selected</label>
                      <input type="number" className="form-control" id="selected" placeholder={selectedRecord.selected} onChange={(e) => setSelectedRecord({ ...selectedRecord, selected: e.target.value })} />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="selected" className="form-label">Revenue</label>
                      <input type="number" className="form-control" id="revenue" placeholder={selectedRecord.revenue} onChange={(e) => setSelectedRecord({ ...selectedRecord, revenue: e.target.value })} />
                    </div>
                    <div className="mb-3" style={{ display: "none" }}>
                      <label htmlFor="target" className="form-label">Target</label>
                      <input type="number" className="form-control" id="target" value="0" readOnly />
                    </div>
                    <div className="mb-3" style={{ display: "none" }}>
                      <label htmlFor="image" className="form-label">Image URL</label>

                      <input type="file" className="form-control" id="image" onChange={(e) => setSelectedRecord({ ...selectedRecord, image: e.target.value })} />
                    </div>
                    <div className="btncontainer">
                      <button type="button" className="btn btn-primary m-2" onClick={handleUpdate}>
                        Save Changes
                      </button>
                      {/* <button className="btn btn-danger m-2" onClick={(e) => handleDelete(selectedRecord.id, e)}>
                        Delete
                      </button> */}
                      <button type="button" className="btn btn-secondary m-2" onClick={closeModal}>
                        Close
                      </button>
                    </div>
                  </form>
                )}
              </div>
            </div>
          </div>
        </div>
      )
      }

    </>
  );
}

export default UserView;