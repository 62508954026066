import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Navbar from './NavBar';
import Announcementslider from "./Announcementslider";


function SubAdminPage(props) {
  const [data, setData] = useState(null);
  const [userPermissions, setUserPermissions] = useState([]);
  const additionalProp = props.dataProp;
  console.log("Additional Prop: ", additionalProp);

  const [focusedIndex, setFocusedIndex] = useState(0);
  const [isAuth, setIsAuth] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [isSuperuser, setIsSuperuser] = useState(false); // State to store superuser status
  const navigate = useNavigate();


  useEffect(() => {
    if (localStorage.getItem('access_token') !== null) {
      setIsAuth(true); // Set authentication status to true if access token is present
      fetch('https://scoreboard.backenddd.in/GetAllRecord/', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        },
      })
        .then(response => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .then(data => {
          console.log("Data: ", data)
          setData(data.records || []); // Update state with fetched data
          setIsSuperuser(data.is_superuser);
          setUserPermissions(data.user_permissions || []);

          console.log("User Permission: ", data.user_permissions)
        })
        .catch(error => {
          console.error('There was a problem with the fetch operation:', error);
        });
    }
  }, []);


  // ############################ Iteration ########################################
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(5);
  const totalPages = Math.ceil((data && data.length) / recordsPerPage);

  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentSubAdminPage = data && data.slice(indexOfFirstRecord, indexOfLastRecord);
  const globalRecordCount = (currentPage - 1) * recordsPerPage;

  const paginate = () => {
    // Move to the next page
    setCurrentPage((prevPage) => (prevPage === totalPages ? 1 : prevPage + 1));
    // setCurrentPage((prevPage) => (prevPage === totalPages ? 4 : 4));

  };


  // ############################ Iteration ########################################


  const openModal = (record) => {
    setSelectedRecord(record);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    setSelectedRecord(null);
  };


  const handleUpdate = () => {
    if (selectedRecord) {
      const formData = new FormData();
      formData.append('id', selectedRecord.id);
      formData.append('name', document.getElementById('recordName').value);
      formData.append('resume_forwarded', document.getElementById('forwarded').value);
      formData.append('pipline', document.getElementById('pipline').value);
      formData.append('turned_up', document.getElementById('turned_up').value);
      formData.append('selected', document.getElementById('selected').value);
      formData.append('target', document.getElementById('target').value);
      // Handle file upload, if available
      const imageInput = document.getElementById('image');
      if (imageInput.files.length > 0) {
        formData.append('image', imageInput.files[0]);
      }

      fetch('https://scoreboard.backenddd.in/UpdateRecord/', {
        method: 'PUT',
        body: formData,
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
            console.log(response)
          }
          return response.json();
        })
        .then((data) => {
          console.log('Updated record:', data);
          closeModal();
          // Re-fetch all records after successful update
          fetch('https://scoreboard.backenddd.in/GetAllRecord/', {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('access_token')}`,
            },
          })
            .then(response => {
              if (!response.ok) {
                throw new Error('Network response was not ok');
              }
              return response.json();
            })
            .then(data => {
              setData(data.records || []); // Update state with fetched data
              setIsSuperuser(data.is_superuser);
            })
            .catch(error => {
              console.error('There was a problem with the fetch operation:', error);
            });
        })
        .catch((error) => {
          console.error('There was a problem with the fetch operation:', error);
          // Handle errors here
        });
    }
  };


  const handleDelete = (id, e) => {
    e.preventDefault();
    const isConfirmed = window.confirm("Are you sure you want to delete this record?");

    if (isConfirmed) {
      fetch(`https://scoreboard.backenddd.in/DelRecord/${id}/`, {
        method: 'DELETE',
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          console.log("Deleted Successfully")
        })
        .then((data) => {
          console.log('Deleted record:', data);
          closeModal();

          fetch('https://scoreboard.backenddd.in/GetAllRecord/', {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('access_token')}`,
            },
          })
            .then(response => {
              if (!response.ok) {
                throw new Error('Network response was not ok');
              }
              return response.json();
            })
            .then(data => {
              setData(data.records || []);
              setIsSuperuser(data.is_superuser);
            })
            .catch(error => {
              console.error('There was a problem with the fetch operation:', error);
            });
        })
        .catch((error) => {
          console.error('There was a problem with the fetch operation:', error);
        });
    };
  };


  return (
    <>
    <Navbar dataProp="tlandbh" />
      <section>
        <div>
          <div>
            <div>
              <div>
                {/* ##################### Not Superuser ################## */}
                {/* {!isSuperuser && (
                  <div>
                    <h3>User Permissions:</h3>
                    <ul>
                      {userPermissions.map(permission => (
                        <li key={permission}>{permission}</li>
                      ))}
                    </ul>
                  </div>
                )} */}

                {/****************************** Viewer Code (Someone) ******************************/}

                  <table className="min-w-full bg-white border border-gray-300"  style={{width: "95%", margin: "auto"}}>
                    <thead>
                      <tr>
                        <th scope="col" className="py-2 px-4 border-b">HR</th>
                        <th scope="col" className="py-2 px-4 border-b">Name</th>
                        <th scope="col" className="py-2 px-4 border-b">Forwarded</th>
                        <th scope="col" className="py-2 px-4 border-b">Pipeline</th>
                        <th scope="col" className="py-2 px-4 border-b">Turned Up</th>
                        <th scope="col" className="py-2 px-4 border-b">Selected</th>
                        <th scope="col" className="py-2 px-4 border-b">Options</th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-300">
                      {data &&
                        data.map((record) => (
                          <tr key={record.id}>
                            <td className="py-2 px-4 border-b">
                              <img src={`https://scoreboard.backenddd.in${record.image}`} className="w-auto" style={{ height: "100px" }} alt="" />
                            </td>
                            <td className="py-2 px-4 border-b text-center">{record.name}</td>
                            <td className="py-2 px-4 border-b text-center">{record.resume_forwarded}</td>
                            <td className="py-2 px-4 border-b text-center">{record.pipline}</td>
                            <td className="py-2 px-4 border-b text-center">{record.turned_up}</td>
                            <td className="py-2 px-4 border-b text-center">{record.selected}</td>

                            <td className="py-2 px-4 border-b text-center">
                              <button className="btn btn-primary" onClick={() => openModal(record)}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-pencil" viewBox="0 0 16 16">
                                  <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325" />
                                </svg>
                              </button>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
              </div>
            </div>
          </div>
        </div>


        <Announcementslider />
      </section>


      {showModal && (
        <div className="modal" tabIndex="-1" role="dialog" style={{ display: 'block' }}>
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Edit Record</h5>
                <button type="button" className="btn-close" onClick={closeModal}></button>
              </div>
              <div className="modal-body">
                {selectedRecord && (
                  <form>
                    <input type="hidden" className="form-control" id="recordId" value={selectedRecord.id} />

                    <div className="mb-3">
                      <label htmlFor="recordName" className="form-label">Record Name</label>
                      <input type="text" className="form-control" id="recordName" value={selectedRecord.name} />
                    </div>

                    <div className="mb-3">
                      <label htmlFor="forwarded" className="form-label">Resume Forwarded</label>
                      <input type="number" className="form-control" id="forwarded" placeholder={selectedRecord.resume_forwarded} readOnly />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="pipline" className="form-label">Pipeline</label>
                      <input type="number" className="form-control" id="pipline" placeholder={selectedRecord.pipline} readOnly />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="turned_up" className="form-label">Turned Up</label>
                      <input type="number" className="form-control" id="turned_up" placeholder={selectedRecord.turned_up} readOnly />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="selected" className="form-label">Selected</label>
                      <input type="number" className="form-control" id="selected" placeholder={selectedRecord.selected} readOnly />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="target" className="form-label">Target</label>
                      <input type="number" className="form-control" id="target" value={selectedRecord.target} onChange={(e) => setSelectedRecord({ ...selectedRecord, target: e.target.value })}  />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="image" className="form-label">Image URL</label>

                      <input type="file" className="form-control" id="image" onChange={(e) => setSelectedRecord({ ...selectedRecord, image: e.target.value })} />
                    </div>
                    <div className="btncontainer">
                      <button type="button" className="btn btn-primary m-2" onClick={handleUpdate}>
                        Save Changes
                      </button>
                      <button className="btn btn-danger m-2" onClick={(e) => handleDelete(selectedRecord.id, e)}>
                        Delete
                      </button>
                      <button type="button" className="btn btn-secondary m-2" onClick={closeModal}>
                        Close
                      </button>
                    </div>
                  </form>
                )}
              </div>
            </div>
          </div>
        </div>
      )
      }

    </>
  );
}
export default SubAdminPage;