// src/components/Navbar.js
import { React, useEffect, useState } from 'react';
import logo from '../assets/images/Pace logo.png'
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import axios from 'axios';

const Navbar = (props) => {
  const navigate = useNavigate();
  const additionalProp = props.dataProp;
  const [userPerm, setUserPerm] = useState(null);
  // const { dataProp } = props;
  // console.log(dataProp)

  const handleSignOut = () => {

    // For illustration purposes, let's clear the access token from localStorage
    localStorage.removeItem("access_token");

    // Redirect to the Pace page
    navigate('/');
  };

  useEffect(() => {
    const fetchUserPerm = async () => {
      try {
        const response = await axios.get("https://scoreboard.backenddd.in/GetUserPermissions/", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        });
        setUserPerm(response.data);

      } catch (error) {
        console.error("Error fetching announcements:", error);
      }
    }

    fetchUserPerm();
  }, []);


  return (


    <nav class="bg-white ">
      <div class="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-1">
        <a href="#" class="flex items-center space-x-3 rtl:space-x-reverse">
          <img src={logo} class="h-8" alt="Leaderboard" />
          <span class="self-center text-3xl font-bold whitespace-nowrap dark:text-white" style={{ color: "#0369a1" }}>Leaderboard</span>
        </a>
        <button data-collapse-toggle="navbar-default" type="button" class="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600" aria-controls="navbar-default" aria-expanded="false">
          <span class="sr-only">Open main menu</span>
        </button>
        <div class="hidden w-full md:block md:w-auto" id="navbar-default">
          <ul class="font-medium flex flex-col p-3 md:p-0 mt-1  rounded-lg bg-gray-50 md:flex-row md:space-x-8 rtl:space-x-reverse md:mt-0 md:border-0 md:bg-white dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700">

            {/* **************************************************  Custom Operations  *********************************************************** */}

            {/* custom_user_view, center_manager_view */}
            {additionalProp === "custom_operation" ? (
              <>
                <a href="/news" class="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent">Announcements</a>

                <a href="/momlist" class="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent">MOM</a>

                <li>
                  <Link
                    className="nav-item dropdown block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent"
                    to={{ pathname: '/', state: { additionalProp } }}
                    onClick={handleSignOut}
                  >
                    Sign Out
                  </Link>

                </li>
              </>
            )
            : additionalProp === "custom_user_view and center_manager_view" ? (
              <>
                {/* If additionalProp is hr_view */}
                <li>
                  <Link to="/hr-dashboard" class="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent">
                    HR Dashboard
                  </Link>
                </li>
            
                <li>
                  <Link to="/employee-management" class="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent">
                    Employee Management
                  </Link>
                </li>
            
                <li>
                  <Link
                    className="nav-item dropdown block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent"
                    to={{ pathname: '/', state: { additionalProp } }}
                    onClick={handleSignOut}
                  >
                    Sign Out
                  </Link>
                </li>
              </>
            ) : (
              <>
                <li>
                  <Link to="/records" class="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent" >Pace</Link>
                </li>
                <li>
                  <Link to="/create" class="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent" >Create</Link>
                </li>

                <li class="nav-item dropdown block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent">
                  <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    Docladder
                  </a>
                  <ul class="dropdown-menu">
                    <li>
                      <Link class="dropdown-item" to={{
                        pathname: '/createdocladdermember',
                        state: { additionalProp }
                      }}>
                        <li>Add Member</li>
                      </Link>
                    </li>

                    <li>
                      <Link class="dropdown-item" to={{
                        pathname: '/showdocladdermember',
                        state: { additionalProp }
                      }}>
                        <li>View Member</li>
                      </Link>
                    </li>

                  </ul>
                </li>

                <li class="nav-item dropdown block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent">
                  <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    Team Leader
                  </a>
                  <ul class="dropdown-menu">
                    <li>
                      <Link class="dropdown-item" to={{
                        pathname: '/createteamleader',
                        state: { additionalProp }
                      }}>
                        <li>Add Team Leader</li>
                      </Link>
                    </li>

                    <li>
                      <Link class="dropdown-item" to={{
                        pathname: '/showteamleader',
                        state: { additionalProp }
                      }}>
                        <li>View Team Leader</li>
                      </Link>
                    </li>

                  </ul>
                </li>
                {/* </>
                )} */}
                <a href="/news" class="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent">Announcements</a>
                <a href="/user-comparison" class="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent">Comparison</a>
                <a href="/marketingFilePage" class="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent">Marketing File</a>
                <a href="/partnersFiles" class="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent">Partners File</a>

                

                {/* marketingFilePage */}
                {/* {userPerm !== "tlandbh" && userPerm !== "hr_permission" && ( */}

                <a href="/momlist" class="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent">MOM</a>


                <li>
                  <Link
                    className="nav-item dropdown block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent"
                    to={{ pathname: '/', state: { additionalProp } }}
                    onClick={handleSignOut}
                  >
                    Sign Out
                  </Link>

                </li>
              </>
            )}

          </ul>
        </div>
      </div>
    </nav>

  );
};

export default Navbar;
