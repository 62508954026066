import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Center,
  Container,
  Flex,
  Heading,
  List,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  Textarea,
  useDisclosure,
  ChakraProvider
} from '@chakra-ui/react';
import { FaRegComment } from 'react-icons/fa';

const Training = () => {
  const [trainings, setTrainings] = useState([]);
  const [currentUser, setCurrentUser] = useState('');
  const [feedback, setFeedback] = useState('');
  const [rating, setRating] = useState(0);

  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    fetchTrainings();
  }, []);

  const fetchTrainings = async () => {
    try {
      const response = await fetch(
        'https://scoreboard.backenddd.in/LeadersTable/new-employee-traininglist/',
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          },
        }
      );
      if (!response.ok) {
        throw new Error('Failed to fetch trainings');
      }
      const data = await response.json();
      setCurrentUser(data.name);
      setTrainings(data.new_training);
    } catch (error) {
      console.error(error);
    }
  };

  const handleFeedbackButtonClick = () => {
    onOpen();
  };

  const handleSubmitFeedback = async (e) => {
    e.preventDefault();
    try {
      const taskToUse = trainings[0].task || trainings[0].oldtask;
      const response = await fetch(
        'https://scoreboard.backenddd.in/LeadersTable/training-feedback/',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          },
          body: JSON.stringify({
            training: trainings[0].id,
            feedback,
            rate: rating,
            user: currentUser,
            task: taskToUse,
          }),
        }
      );
      if (!response.ok) {
        throw new Error('Failed to submit feedback');
      }
      onClose();
      setFeedback('');
      setRating(0);
    } catch (error) {
      console.error(error);
    }
  };

  return (

    <ChakraProvider>
    <Container maxW="container.sm">
      <Center mb={8}>
        <Heading>New Employee Training List</Heading>
      </Center>
      <Box mb={4}>
        <Text>Welcome, {currentUser}!</Text>
      </Box>
      <List>
        {trainings.map((training) => (
          <ListItem key={training.id} border="1px" borderColor="gray.200" p={2} rounded="md" mb={2}>
            <Text>
              <strong>Topic:</strong> {training.topic || training.old_emp_training_topic}
            </Text>
            <Text>
              <strong>Date:</strong> {training.tdate || training.old_t_date}
            </Text>
          </ListItem>
        ))}
      </List>
      <Flex justify="center" mt={8}>
        <Button leftIcon={<FaRegComment />} colorScheme="teal" onClick={handleFeedbackButtonClick}>
          Give Feedback
        </Button>
      </Flex>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Feedback Form</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <form onSubmit={handleSubmitFeedback}>
              <Textarea
                placeholder="Enter your feedback"
                value={feedback}
                onChange={(e) => setFeedback(e.target.value)}
                mb={4}
              />
              <fieldset>
                <legend>Rating out of 10:</legend>
                {[...Array(10).keys()].map((num) => (
                  <label key={num} style={{ marginRight: '8px' }}>
                    <input
                      type="radio"
                      name="rating"
                      value={num + 1}
                      onChange={(e) => setRating(parseInt(e.target.value))}
                    />
                    {num + 1}
                  </label>
                ))}
              </fieldset>
              <Flex justify="center" mt={6}>
                <Button colorScheme="teal" type="submit">
                  Submit
                </Button>
              </Flex>
            </form>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Container>

    </ChakraProvider>
  );
};

export default Training;
