import React, { useState, useEffect, useRef } from "react";
import qimg from "../../assets/images/qoutes.png";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'react-calendar/dist/Calendar.css';
import { Link } from "react-router-dom";


const quotes = [
  "A diamond is a piece of coal that is stuck to the job. Your most important work is always ahead of you, never behind you.",
  "The difference between ordinary and extraordinary is that little extra.",
  "Don’t try to be the best, try to be a better version than yesterday!",
  "There are no shortcuts to any place worth going.",
  "Believing in yourself is the first secret to success.",
  "Positive attitude will lead you to a positive outcome",
  "The only real limitation on your abilities is the level of your desires. If you want it badly enough, there are no",
  "Work hard in silence, Let your success make noise.",
  "Never give up because great things take time and some effort.",
  "Sooner or later, those who win are those who think they can.",
  "Trust yourself, you know more than you think you do.",
  "I was always looking outside myself for strength and confidence but it comes from within. It is there all the time.",
  "The expert in anything was once a beginner.",
];

const DocladderRecordSlider = () => {
  const [data, setData] = useState(null);
  const [userdata, setUserData] = useState(null);

  const [dailyEntryData, setdailyEntryData] = useState([]);

  const [focusedIndex, setFocusedIndex] = useState(0);
  const [isAuth, setIsAuth] = useState(false);
  const sliderContainer = useRef(null);

  const [userName, setUserName] = useState(""); // Define state for userName
  const [turnedUp, setTurnedUp] = useState(""); // Define state for turnedUp
  const [selected, setSelected] = useState(""); // Define state for turnedUp
  const [revenue, setRevenue] = useState(""); // Define state for turnedUp

  const [resume_forwarded, setResumeForwarded] = useState(""); // Define state for turnedUp

  const [userTarget, setUserTarget] = useState([]); // Define state for turnedUp
  const [userRevenueTarget, setUserRevenueTarget] = useState([]); // Define state for turnedUp

  const [imageSrc, setImageSrc] = useState(""); // Define state for imageSrc  const [announcements, setAnnouncements] = useState([]);
  const [recordData, setRecordData] = useState([]);
  const [messageShown, setMessageShown] = useState(false);
  const [pipelineandturnedup, setPipelineandturnedup] = useState(null);

  const navigate = useNavigate();


  const handleSignOut = () => {

    localStorage.removeItem("access_token");

    // Redirect to the home page
    navigate('/');
  };

  useEffect(() => {
    if (localStorage.getItem("access_token") !== null) {
      setIsAuth(true); // Set authentication status to true if access token is present
      fetch("https://scoreboard.backenddd.in/Docladder/daily-user-records/", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.json();
        })
        .then((data) => {
          console.log("Data: ", data.monthly_records)
          setData(data.monthly_records || []); // Update state with fetched data
          setdailyEntryData(data.monthly_records || []); // Update state with fetched data
          setUserData(data.records || []); // Update state with fetched data

          setPipelineandturnedup(data.pipelineandturnedup || [])

          console.log("data Latest News: ", data.latest_news)
        })
        .catch((error) => {
          console.error("There was a problem with the fetch operation:", error);
        });
    }
  }, []);


  useEffect(() => {
    const interval = setInterval(() => {
      if (data) {
        setFocusedIndex((prevIndex) => {
          // const resumeForwardedPercentage = (data[focusedIndex].resume_forwarded / data[focusedIndex].target) * 100;
          const currentRecord = data[prevIndex];
          console.log("Current Record: ", currentRecord)
          console.log("Current Resume Forwarded: ", currentRecord.resume_forwarded)
          // Check if both resume_forwarded and target are defined and not zero
          if (currentRecord && currentRecord.resume_forwarded !== null && currentRecord.target !== null && currentRecord.target !== 0) {
            const resumeForwardedPercentage = (currentRecord.resume_forwarded / currentRecord.target) * 100;
            console.log("Hello: '", resumeForwardedPercentage);
            if ((currentRecord.target - currentRecord.resume_forwarded === 1 || currentRecord.target - currentRecord.resume_forwarded === 2) && !messageShown) {
              console.log(`Only ${currentRecord.target - currentRecord.resume_forwarded} resume left!`);
            }
          } else {
            console.log("Unable to calculate percentage: invalid data");
          }
          if (prevIndex === data.length - 1) {
            // navigate('/viewteamlead');
            navigate('/tlslider');

            // 
          }
          else {
          }
          return (prevIndex + 1) % data.length;
        });
      }
    }, 5000);
    return () => clearInterval(interval);
  }, [data]);



  // Get Color Combination
  const getResumeForwardedColor = (resumeForwarded, target) => {
    const percentage = (resumeForwarded / target) * 100;

    // if (percentage < 10){
    //   return "#b91c1c" // Dark red
    // }
    if (percentage < 50) {
      return "#FF5757"; //  rose red
    } else if (percentage >= 50 && percentage < 70) {
      return "#FFDE59"; // yellow
    } else if (percentage >= 70 && percentage < 90) {
      return "#FFBD59"; // orange
    } else if (percentage <= 100) {
      return "#7ED957"; // green
    }
    else {
      return "#0284c7"; // Blue For Excellent
    }
  };

  useEffect(() => {
    const fetchRecordData = async () => {
      try {
        const currentRecord = data[focusedIndex];
        const response = await axios.get(`https://scoreboard.backenddd.in/Docladder/record/${currentRecord.id}/`);
        console.log("Response Data: ", response.data)
        setRecordData(response.data);
      } catch (error) {
        console.error('Error fetching record data:', error);
      }
    };

    fetchRecordData();
  }, [focusedIndex, data]);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    // Add any other settings you need
  };


  const sortedData = [...dailyEntryData].sort((a, b) => {
    // Get the pipeline values from pipelineAndTurnedUpEntry
    const pipelineA = pipelineandturnedup.find(entry => entry.user === a.user)?.pipline || 0;
    const pipelineB = pipelineandturnedup.find(entry => entry.user === b.user)?.pipline || 0;

    // First, sort by revenue in descending order
    if (b.revenue !== a.revenue) {
      return b.revenue - a.revenue; // Sort in descending order by revenue
    }

    // If revenue is the same, sort by selected in descending order
    if (b.selected !== a.selected) {
      return b.selected - a.selected; // Sort in descending order by selected
    }


    // Calculate the percentage of turned_up based on resume_forwarded for both a and b
    const percentageA = a.resume_forwarded !== 0 ? (a.turned_up / a.resume_forwarded) * 100 : 0;
    const percentageB = b.resume_forwarded !== 0 ? (b.turned_up / b.resume_forwarded) * 100 : 0;

    console.log("% A: ", percentageA)
    console.log("% B: ", percentageB)

    // Sort by the percentage in descending order
    if (percentageB !== percentageA) {
      return percentageB - percentageA; // Sort in descending order by the percentage
    }

    // If selected is the same, sort by resume_forwarded in descending order
    if (b.resume_forwarded !== a.resume_forwarded) {
      return b.resume_forwarded - a.resume_forwarded; // Sort in descending order by resume forwarded
    }

    // If resume_forwarded is the same, sort by pipeline in descending order
    if (pipelineB !== pipelineA) {
      return pipelineB - pipelineA;
    }
    
    // If pipeline is the same, sort by turned up in descending order
    if (b.turned_up !== a.turned_up) {
      return b.turned_up - a.turned_up; // Sort in descending order by turned up
    }
    
    return 0; // If all criteria are the same, keep the original order
  });


  useEffect(() => {
    if (userdata && data && pipelineandturnedup) {
      // Find the corresponding record in userdata
      const correspondingRecord = userdata.find((record) => record.id === sortedData[focusedIndex].user);

      // If a corresponding record is found, extract the image and name
      const imageSrc = correspondingRecord ? `https://scoreboard.backenddd.in${correspondingRecord.image}` : '';
      const userName = correspondingRecord ? correspondingRecord.name : '';
      const target = correspondingRecord ? correspondingRecord.target : '';
      const targetrev = correspondingRecord ? correspondingRecord.revenue : '';

      // Find the corresponding entry in pipelineandturnedup
      const pipelineAndTurnedUpEntry = pipelineandturnedup.find((entry) => entry.user === sortedData[focusedIndex].user);
      // If corresponding entry is found, extract the turned_up value
      const turnedUpValue = pipelineAndTurnedUpEntry ? pipelineAndTurnedUpEntry.turned_up : '';

      // Update state variables with the extracted values
      setImageSrc(imageSrc);
      setUserName(userName);
      setUserTarget(target); // Target is fetched from data
      setUserRevenueTarget(targetrev)
      setTurnedUp(sortedData[focusedIndex].turned_up); // Turned up is fetched from data
      setSelected(sortedData[focusedIndex].selected); // Turned up is fetched from data
      setRevenue(sortedData[focusedIndex].revenue); // Turned up is fetched from data
      setResumeForwarded(sortedData[focusedIndex].resume_forwarded); // Turned up is fetched from data
      
    }
  }, [focusedIndex, userdata, data, pipelineandturnedup, userTarget]);


  return (

    <div className="relative sliderContainer">
      <Slider {...settings}>
        {dailyEntryData &&
          sortedData.map((dailyEntry, index) => {

            return (
              <div key={index} class="recentContainer">
                <div class="row" style={{ marginLeft: "35px" }}>
                  <div class="col-12">
                    <div id="carouselExample" class="carousel slide">
                      <div class="carousel-inner">
                        <div class="carousel-item active">
                          <div class="row">
                            <div class="col-7">
                              <div class="row">
                                <div class="col-8" style={{ marginTop: "30px" }}>
                                  <h1 class="authorName">
                                    {userName}
                                  </h1>
                                  <p class="authorPos">HR Associate</p>
                                  <Link
                                    className="signOutLinkRecord"
                                    to={{ pathname: '/' }}
                                    onClick={handleSignOut}
                                  > <i class="fa-solid fa-right-from-bracket"></i></Link>
                                </div>
                                <div class="col-4" style={{ marginTop: "30px" }}>
                                  <div class="targetBox">
                                    {data && data[focusedIndex] && (
                                      <React.Fragment key={data[focusedIndex].id}>
                                        <h2>
                                          {/* {userTarget} / <span style={{ fontSize: '1rem' }}>{userRevenueTarget} lac</span> */}
                                          {userTarget}

                                        </h2>

                                      </React.Fragment>
                                    )}
                                    <p>Target</p>
                                  </div>
                                </div>
                              </div>
                              <div class="row">
                                {data && data[focusedIndex] && (
                                  <>
                                    <div class="col-4" style={{ marginTop: "30px" }}>
                                      <div class="resumeForward" style={{
                                        backgroundColor: getResumeForwardedColor(
                                          data[focusedIndex].resume_forwarded,
                                          userTarget,
                                        ),
                                        color: "#fff",
                                      }}>
                                        {/* <h2>{data[focusedIndex].resume_forwarded}</h2> */}
                                        <React.Fragment key={data[focusedIndex].id}>
                                          <h2>
                                            {resume_forwarded}

                                          </h2>
                                        </React.Fragment>

                                        <p>Resume Forwarded</p>
                                      </div>
                                      {/* Display message for a difference of 1 or 2 */}
                                      {data[focusedIndex].target - data[focusedIndex].resume_forwarded === 1 && (
                                        <p>Only 1 resume left!</p>
                                      )}
                                      {data[focusedIndex].target - data[focusedIndex].resume_forwarded === 2 && (
                                        <p>Only 2 resumes left!</p>
                                      )}
                                    </div>
                                    <div class="col-4" style={{ marginTop: "30px" }}>
                                      <div class="turnedUp">
                                        {/* <h2>{data[focusedIndex].turned_up}</h2> */}

                                        <h2>
                                          {turnedUp}
                                        </h2>
                                        <p>Turned Up</p>
                                      </div>
                                    </div>
                                    <div class="col-4" style={{ marginTop: "30px" }}>
                                      <div class="selectionCand">
                                        {/* <h2>{data[focusedIndex].selected}</h2> */}
                                       
                                            <h2>
                                              {/* {selected} / <span style={{ fontSize: "1rem" }}>{revenue} lac</span> */}
                                              {selected}

                                            </h2>
                                       
                                        <p>Selected Candidate</p>
                                      </div>
                                    </div>
                                  </>
                                )}

                                <div className="col-12" style={{ marginTop: "30px" }}>
                                  <div class="qoutesBoxx">
                                    <img src={qimg} />
                                    <q className="text-3xl">{quotes[focusedIndex]}</q>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              class="col-5 userImageDiv"
                              style={{ marginTop: "30px" }}
                            >
                              <img
                                src={imageSrc}
                                alt=""
                                class="userImage"
                              />
                              <div class="ranking">
                                <h2>{focusedIndex + 1}</h2>
                                <p>Ranking</p>
                              </div>
                            </div>
                          </div>
                          <div class="row"></div>
                        </div>
                        <div class="carousel-item"></div>
                        <div class="carousel-item"></div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            )
          })
        }
      </Slider>
    </div>
  );
};

export default DocladderRecordSlider;
