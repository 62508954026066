import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Navbar from "../../NavBar";
import "./LeaderTasks.css";
import axios from 'axios';

function LeaderTasks() {
  const { leaderId } = useParams();
  const [tasks, setTasks] = useState([]);
  const [dailyEntryData, setdailyEntryData] = useState([]);
  const [users, setUsers] = useState([]);
  const [currentTasks, setCurrentTasks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [totalPoints, setTotalPoints] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedTask, setSelectedTask] = useState({ name: '', value: '', points: '' });
  const [inputValues, setInputValues] = useState({ name: '', value: '', points: '', ename: '', date_joined: '', date_handovered: '', targetdays: '' });
  const { name, value, points, ename, date_joined, date_handovered, targetdays } = inputValues;

  useEffect(() => {
    async function fetchTasks() {
      try {
        if (leaderId) {
          const response = await fetch(`https://scoreboard.backenddd.in/LeadersTable/leader/${leaderId}/TargetsOfTasks/`);
          if (!response.ok) {
            throw new Error('Failed to fetch tasks');
          }
          const data = await response.json();
          console.log("Data: ", data)
          setTasks(data);
        }
        setLoading(false);
      } catch (error) {
        console.error("Error ", error);
        setLoading(false);
      }
    }

    fetchTasks();
  }, [leaderId]);

  useEffect(() => {
    let pointsSum = 0;
    tasks.forEach(task => {
      pointsSum += task.points;
    });
    setTotalPoints(pointsSum);
  }, [tasks]);

  useEffect(() => {
    async function fetchLeaders() {
      try {
        const response = await fetch(`https://scoreboard.backenddd.in/LeadersTable/leaders_id/${leaderId}/`);
        if (response.ok) {
          const responseData = await response.json();
          console.log("Person Response: ", responseData);

          setdailyEntryData(responseData.leader.currentVal);
          setTasks(responseData.leader.tasks);
        } else {
          console.log("Error fetching leader data");
        }
      } catch (error) {
        throw new Error('Failed to fetch leaders');
      }
    }

    fetchLeaders();
  }, [leaderId]);

  const openModal = (task) => {
    setSelectedTask(task);
    setInputValues({
      name: task.name,
      value: task.value,
      points: task.points,
      targetdays: task.name === "Induction" ? '1' : (task.name === "Smooth handover Process" ? '4' : ''),
    });
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setSelectedTask(null);
    setIsModalOpen(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`https://scoreboard.backenddd.in/LeadersTable/CreateTargetsOfTasks/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          name: selectedTask.id,
          value,
          ename,
          date_joined,
          date_handovered,
          targetdays
        }),
      });
      if (!response.ok) {
        throw new Error('Failed to save current month task');
      }
      closeModal();
    } catch (error) {
      console.error("Error ", error);
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <Navbar />
      <div className='container-fluid m-auto'>
        <div className='row mt-3'>
          <div className='col-12'>
            <h2 className="taskHeader">Tasks for Leader {leaderId}</h2>
          </div>
        </div>

        <div className='tableTaskDiv m-auto'>
          <table className="tabletaskList w-100 text-center">
            <thead>
              <tr className='taskRow'>
                <th>Task No.</th>
                <th>Task Name</th>
                <th>Target</th>
                <th>Achieved Target</th>
                <th>Achieved Points</th>
                <th>Total Points</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {tasks.map((task, index) => {
                const matchedEntry = dailyEntryData.find(entry => entry.name_id === task.id);
                return (
                  <tr key={task.id}>
                    <td>{index + 1}</td>
                    <td>{task.name}</td>
                    <td>{task.target}</td>
                    <td>{matchedEntry ? matchedEntry.total_taskinput : '-'}</td>
                    <td>{matchedEntry ? matchedEntry.total_points.toFixed(2) : '-'}</td>
                    <td>{task.points}</td>
                    <td>
                      <button className='leaderTaskBtn' onClick={() => openModal(task)}>
                        <i className="fa-solid fa-check"></i> Complete
                      </button>
                    </td>
                  </tr>
                );
              })}
              {currentTasks.map((task, index) => (
                <tr key={task.name_id}>
                  <td>{tasks.length + index + 1}</td>
                  <td>{task.task_name}</td>
                  <td>{task.target || '-'}</td>
                  <td>{task.total_taskinput}</td>
                  <td>{task.total_points}</td>
                  <td>
                    <button className='leaderTaskBtn' onClick={() => openModal(task)}>
                      <i className="fa-solid fa-check"></i> Complete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <h3 className='taskPoint'>Total Points: {totalPoints}</h3>
      </div>

      {isModalOpen && (
        <div className="modal leaderTaskModel">
          <div className="modal-content">
            <h2>Complete Task</h2>
            <form onSubmit={handleSubmit}>
              <label className="form-label">Id:</label>
              <input type="text" value={selectedTask.id} id='name' disabled />

              <label>Value:</label>
              <input type="text" value={value} onChange={(e) => setInputValues({ ...inputValues, value: e.target.value })} id='value' />

              {selectedTask.name === "Induction" && (
                <>
                  <label>Name:</label>
                  <input type="text" value={ename} onChange={(e) => setInputValues({ ...inputValues, ename: e.target.value })} id='ename' />

                  <label>target Days:</label>
                  <input type="text" value={targetdays} readOnly id='targetdays' />

                  <label>Date Joined:</label>
                  <input type="text" value={date_joined} onChange={(e) => setInputValues({ ...inputValues, date_joined: e.target.value })} id='date_joined' />

                  <label>Date Of Handover:</label>
                  <input type="text" value={date_handovered} onChange={(e) => setInputValues({ ...inputValues, date_handovered: e.target.value })} id='date_handovered' />
                </>
              )}

              {selectedTask.name === "Smooth handover Process" && (
                <>
                  <label>Name:</label>
                  <input type="text" value={ename} onChange={(e) => setInputValues({ ...inputValues, ename: e.target.value })} id='ename' />

                  <label>target Days:</label>
                  <input type="text" value={targetdays} readOnly id='targetdays' />

                  <label>Date Joined:</label>
                  <input type="text" value={date_joined} onChange={(e) => setInputValues({ ...inputValues, date_joined: e.target.value })} id='date_joined' />

                  <label>Date Of Handover:</label>
                  <input type="text" value={date_handovered} onChange={(e) => setInputValues({ ...inputValues, date_handovered: e.target.value })} id='date_handovered' />
                </>
              )}
              <button type="submit" className='saveBtn'>Save</button>
              <button type="button" className='cancelBtn' onClick={closeModal}>Cancel</button>
            </form>
          </div>
        </div>
      )}
    </div>
  );
}

export default LeaderTasks;
