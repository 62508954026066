import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { Link } from "react-router-dom";
// import { useNavigate } from "react-router-dom";


function UserWiseMarkettingView({ prop }) {
    const location = useLocation();
    const stateValue = location.state && location.state['userPermissions'];

    const [data, setData] = useState(null);
    const [userPermissions, setUserPermissions] = useState([]);

    const [isAuth, setIsAuth] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [selectedRecord, setSelectedRecord] = useState(null);
    const [isSuperuser, setIsSuperuser] = useState(false);
    const navigate = useNavigate();
    const [markettingTypes, setMarkettingTypes] = useState([]);
    const [targetData, setTargetData] = useState(null);
    const [ddE2Estatus, setDDE2EStatus] = useState('');
    const [weeklyData, setWeeklyData] = useState(null);


    const handleSignOut = () => {
        // Perform sign-out logic here, for example, clearing the authentication token.
        // After signing out, you can redirect the user to the desired page.
        // In this case, let's redirect them to the home page.

        // For illustration purposes, let's clear the access token from localStorage
        localStorage.removeItem("access_token");

        // Redirect to the home page
        navigate('/');
    };




    useEffect(() => {
        if (localStorage.getItem("access_token") !== null) {
            setIsAuth(true);
            fetch(`https://scoreboard.backenddd.in/GetPerUserMarkettingDetails/`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                },
            })
                .then((response) => {
                    if (!response.ok) {
                        throw new Error("Network response was not ok");
                    }
                    return response.json();
                })
                .then((data) => {
                    console.log("Data: ", data)
                    setData(data.records || null);
                    setWeeklyData(data.monthly_records);

                    setTargetData(data.target || null);
                    const targetobj = JSON.parse(data.target)
                    const targetVal = targetobj[0].fields;
                    console.log("dskjfbas: ", targetVal)
                    const tddE2E = targetVal.ddE2E;
                    setDDE2EStatus(tddE2E)
                    console.log("Data: ", tddE2E)
                })
                .catch((error) => {
                    console.error("There was a problem with the fetch operation:", error);
                });
        }
    }, []);

    const handleUpdate = (e) => {
        e.preventDefault();

        if (selectedRecord) {
            const formData = new FormData();
            formData.append("id", selectedRecord.id);
            formData.append("name", document.getElementById("name").value);
            formData.append("mtype", document.getElementById("mtype").value);
            formData.append("total_prefered_location", document.getElementById("total_prefered_location").value);
            formData.append("ddE2E", document.getElementById("ddE2E").value);
            formData.append("DDclient", document.getElementById("DDclient").value);
            formData.append("dailyVisit", document.getElementById("dailyVisit").value);
            formData.append("docladerId", document.getElementById("docladerId").value);
            formData.append("pace", document.getElementById("pace").value);

            // Adjust other fields based on your Marketting model

            // Handle file upload, if available
            const imageInput = document.getElementById("image");
            if (imageInput.files.length > 0) {
                formData.append("image", imageInput.files[0]);
            }

            fetch(`https://scoreboard.backenddd.in/updatemarkettingrecord/`, {
                method: "PUT",
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                },
                body: formData,
            })
                .then((response) => {
                    if (!response.ok) {
                        throw new Error("Network response was not ok");
                    }
                    return response.json();
                })
                .then((data) => {
                    console.log("Updated record:", data);
                    closeModal();
                    // Fetch updated records or update state as needed

                    fetch("https://scoreboard.backenddd.in/GetPerUserMarkettingDetails/", {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                        },
                    })
                        .then((response) => {
                            if (!response.ok) {
                                throw new Error("Network response was not ok");
                            }
                            return response.json();
                        })
                        .then((data) => {
                            // setData(data || []); // Update state with fetched data
                            console.log("Hello")
                            console.log("Data: ", data)
                            setData(data.records || null);
                            setWeeklyData(data.monthly_records);

                            setTargetData(data.target || null);
                            const targetobj = JSON.parse(data.target)
                            const targetVal = targetobj[0].fields;
                            const tddE2E = targetVal.ddE2E;
                            setDDE2EStatus(tddE2E)

                        })
                        .catch((error) => {
                            console.error("There was a problem with the fetch operation:", error);
                        });
                })
                .catch((error) => {
                    console.error("There was a problem with the fetch operation:", error);
                });
        }
    };


    const openModal = (record) => {
        setSelectedRecord(record);
        setShowModal(true);
    };

    const closeModal = () => {
        setShowModal(false);
        setSelectedRecord(null);
    };


    const [announcements, setAnnouncements] = useState([]);

    useEffect(() => {
        const fetchAnnouncements = async () => {
            try {
                const response = await axios.get(
                    "https://scoreboard.backenddd.in/ShowAnnouncements/"
                );
                setAnnouncements(response.data);
            } catch (error) {
                console.error("Error fetching announcements:", error);
            }
        };

        fetchAnnouncements();
    }, []);

    return (
        <>
            <section>
                <div>
                    <div>
                        <div>
                            <div>
                                <table className="myTable min-w-full bg-white" style={{ width: "95%", margin: "auto" }}>
                                    <thead>
                                        <tr>
                                            <th scope="col" className="hrWidth">
                                                Image
                                            </th>
                                            <th scope="col" className="nameWidth">
                                                Name
                                            </th>
                                            <th scope="col" className="resumeWidth">
                                                Prefered Loc
                                            </th>
                                            <th scope="col" className="selWidth">
                                                Pace
                                            </th>
                                            {ddE2Estatus != null && ddE2Estatus != 0 ? (
                                                <th scope="col" className="turWidth">
                                                    DDE2E
                                                </th>
                                            ) : null}
                                            <th scope="col" className="selWidth">
                                                DDClient
                                            </th>
                                            <th scope="col" className="selWidth">
                                                dailyVisit
                                            </th>
                                            <th scope="col" className="selWidth">
                                                docladerId
                                            </th>
                                            <th className="col">Options

                                                <Link
                                                    className="signOutLink"
                                                    to={{ pathname: '/' }}
                                                    onClick={handleSignOut}
                                                >
                                                    <i class="fa-solid fa-right-from-bracket"></i>
                                                </Link>
                                            </th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {data && (
                                            <tr key={data.id}>
                                                <td className="py-2 px-4 border-b">
                                                    <img src={`https://scoreboard.backenddd.in${data.image}`} className="w-auto" style={{ height: "100px" }} alt="" />
                                                </td>
                                                <td className="py-2 px-4 border-b text-center">{data.name}</td>
                                                <td className="py-2 px-4 border-b text-center">{data.total_prefered_location}</td>
                                                <td className="py-2 px-4 border-b text-center">{data.pace}</td>
                                                {ddE2Estatus !== 0 && ddE2Estatus !== null ? (
                                                    <td className="py-2 px-4 border-b text-center">{data.ddE2E}</td>
                                                ) : null}
                                                <td className="py-2 px-4 border-b text-center">{data.DDclient}</td>
                                                <td className="py-2 px-4 border-b text-center">{data.dailyVisit}</td>
                                                <td className="py-2 px-4 border-b text-center">{data.docladerId}</td>
                                                <td className="py-2 px-4 border-b text-center">
                                                    <button className="btn btn-primary" onClick={() => openModal(data)}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-pencil" viewBox="0 0 16 16">
                                                            <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325" />
                                                        </svg>
                                                    </button>
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="recentContainer">
                    <div className="row recentNews">
                        <div className="col-3">
                            <h4>Recent Event</h4>
                        </div>
                        <div className="col-9">
                            <Slider
                                dots={false}
                                infinite={true}
                                speed={500}
                                slidesToShow={1}
                                slidesToScroll={1}
                                autoplay={true}
                                autoplaySpeed={5000}
                                prevArrow={<></>}
                                nextArrow={<></>}
                            >
                                {announcements.map((announcement) => (
                                    <div key={announcement.id}>
                                        <span style={{ fontSize: "1.2rem" }}>
                                            {announcement.name}
                                        </span>
                                    </div>
                                ))}
                            </Slider>
                        </div>
                    </div>
                </div>
            </section>
            {showModal && (
                <div className="modal" tabIndex="-1" role="dialog" style={{ display: "block" }}>
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Edit Marketting Record</h5>
                                <button type="button" className="btn-close" onClick={closeModal}></button>
                            </div>
                            <div className="modal-body">
                                {selectedRecord && (
                                    <form onSubmit={handleUpdate}>
                                        <input type="hidden" className="form-control" id="recordId" value={selectedRecord.id} />

                                        <div className="mb-3">
                                            <label htmlFor="recordName" className="form-label">
                                                Name
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="name"
                                                value={selectedRecord.name}
                                                readOnly
                                            />
                                        </div>


                                        <div className="mb-3">
                                            <label htmlFor="mtype" className="form-label">
                                                Marketing Type
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="mtype"
                                                value={selectedRecord.mtype}
                                                readOnly
                                            />
                                        </div>

                                        <div className="mb-3">
                                            <label htmlFor="total_prefered_location" className="form-label">
                                                Total Prefered Location Amount
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="total_prefered_location"
                                                placeholder={selectedRecord.total_prefered_location}
                                                onChange={(e) =>
                                                    setSelectedRecord({
                                                        ...selectedRecord,
                                                        total_prefered_location: e.target.value,
                                                    })
                                                }
                                            />
                                        </div>
                                        {ddE2Estatus !== 0 && ddE2Estatus !== null ? (
                                            <div className="mb-3">
                                                <label htmlFor="ddE2E" className="form-label">
                                                    Docladder E2E Amount
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="ddE2E"
                                                    placeholder={selectedRecord.ddE2E}
                                                    onChange={(e) =>
                                                        setSelectedRecord({
                                                            ...selectedRecord,
                                                            ddE2E: e.target.value,
                                                        })
                                                    }
                                                />
                                            </div>
                                        ) : <input
                                            type="hidden"
                                            className="form-control"
                                            id="ddE2E"
                                            value="null"
                                            onChange={(e) =>
                                                setSelectedRecord({
                                                    ...selectedRecord,
                                                    ddE2E: e.target.value,
                                                })
                                            }
                                        />}
                                        <div className="mb-3">
                                            <label htmlFor="DDclient" className="form-label">
                                                DD Client
                                            </label>
                                            <input
                                                type="number"
                                                className="form-control"
                                                id="DDclient"
                                                placeholder={selectedRecord.DDclient}
                                                onChange={(e) =>
                                                    setSelectedRecord({
                                                        ...selectedRecord,
                                                        DDclient: e.target.value,
                                                    })
                                                }
                                            />
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="dailyVisit" className="form-label">
                                                Daily Visit
                                            </label>
                                            <input
                                                type="number"
                                                className="form-control"
                                                id="dailyVisit"
                                                placeholder={selectedRecord.dailyVisit}
                                                onChange={(e) =>
                                                    setSelectedRecord({
                                                        ...selectedRecord,
                                                        dailyVisit: e.target.value,
                                                    })
                                                }
                                            />
                                        </div>

                                        <div className="mb-3">
                                            <label htmlFor="docladerId" className="form-label">
                                                Docladder Id
                                            </label>
                                            <input
                                                type="number"
                                                className="form-control"
                                                id="docladerId"
                                                placeholder={selectedRecord.docladerId}
                                                onChange={(e) =>
                                                    setSelectedRecord({
                                                        ...selectedRecord,
                                                        docladerId: e.target.value,
                                                    })
                                                }
                                            />
                                        </div>

                                        <div className="mb-3">
                                            <label htmlFor="pace" className="form-label">
                                                Pace Amount
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="pace"
                                                placeholder={selectedRecord.pace}
                                                onChange={(e) =>
                                                    setSelectedRecord({
                                                        ...selectedRecord,
                                                        pace: e.target.value,
                                                    })
                                                }
                                            />
                                        </div>

                                        <div className="mb-3" style={{ display: "none" }}>
                                            <label htmlFor="image" className="form-label">
                                                Image
                                            </label>

                                            <input
                                                type="file"
                                                className="form-control"
                                                id="image"
                                                onChange={(e) =>
                                                    setSelectedRecord({
                                                        ...selectedRecord,
                                                        image: e.target.value,
                                                    })
                                                }

                                            />
                                        </div>

                                        <div className="btncontainer">
                                            <button type="submit" className="btn btn-primary m-2">
                                                Save Changes
                                            </button>

                                            <button type="button" className="btn btn-secondary m-2" onClick={closeModal}>
                                                Close
                                            </button>
                                        </div>
                                    </form>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}

export default UserWiseMarkettingView;
