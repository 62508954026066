import React, { useState, useEffect } from "react";
import axios from "axios";
import Navbar from "../NavBar";

const AddFileType = () => {
  const [fileTypes, setFileTypes] = useState([]);
  const [fileTypeName, setFileTypeName] = useState("");
  const [selectedFileType, setSelectedFileType] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const [message, setMessage] = useState("");

  // Fetch File Types
  useEffect(() => {
    fetchFileTypes();
  }, []);

  const fetchFileTypes = async () => {
    try {
      const response = await axios.get(
        "https://scoreboard.backenddd.in/PartnerFiles/filetypes/"
      );
      setFileTypes(response.data);
    } catch (error) {
      console.error("Error fetching file types:", error);
    }
  };

  // Handle Form Submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!fileTypeName.trim()) {
      setMessage("File type name is required");
      return;
    }

    try {
      if (isEdit) {
        await axios.put(
          `https://scoreboard.backenddd.in/PartnerFiles/filetypes/${selectedFileType.id}/`,
          { name: fileTypeName }
        );
        setMessage("File Type updated successfully!");
      } else {
        await axios.post("https://scoreboard.backenddd.in/PartnerFiles/filetypes/", {
          name: fileTypeName,
        });
        setMessage("File Type added successfully!");
      }
      setFileTypeName("");
      setIsEdit(false);
      setSelectedFileType(null);
      fetchFileTypes();
    } catch (error) {
      console.error("Error saving file type:", error);
    }
  };

  // Handle Edit
  const handleEdit = (fileType) => {
    setFileTypeName(fileType.name);
    setSelectedFileType(fileType);
    setIsEdit(true);
  };

  // Handle Delete
  const handleDelete = async (id) => {
    try {
      await axios.delete(`https://scoreboard.backenddd.in/PartnerFiles/filetypes/${id}/`);
      setMessage("File Type deleted successfully!");
      fetchFileTypes();
    } catch (error) {
      console.error("Error deleting file type:", error);
    }
  };

  return (
    <>
      <Navbar />
      <div className="max-w-2xl mx-auto mt-3 p-4 bg-white shadow-lg rounded-md">
        <h2 className="text-2xl font-bold text-blue-700 mb-4">
          {isEdit ? "Edit File Type" : "Add File Type"}
        </h2>
        {message && (
          <div className="mb-4 p-2 text-green-800 bg-green-100 border border-green-300 rounded">
            {message}
          </div>
        )}
        <form onSubmit={handleSubmit} className="mb-6">
          <div className="mb-4">
            <input
              type="text"
              placeholder="Enter file type name"
              value={fileTypeName}
              onChange={(e) => {
                setFileTypeName(e.target.value);
                setMessage("");
              }}
              className="w-full px-4 py-2 border rounded focus:outline-none focus:ring focus:ring-blue-200"
              required
            />
          </div>
          <button
            type="submit"
            className="w-full bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700 transition duration-200"
          >
            {isEdit ? "Update" : "Add"}
          </button>
        </form>

        <h3 className="text-xl font-bold text-gray-800 mb-4">File Types</h3>
        <ul className="space-y-2">
          {fileTypes.length > 0 ? (
            fileTypes.map((fileType) => (
              <li
                key={fileType.id}
                className="p-4 bg-gray-100 rounded shadow flex items-center justify-between"
              >
                <span>{fileType.name}</span>
                <div className="flex space-x-2">
                  <button
                    onClick={() => handleEdit(fileType)}
                    className="px-3 py-1 bg-yellow-500 text-white rounded hover:bg-yellow-600 transition duration-200"
                  >
                    Edit
                  </button>
                  <button
                    onClick={() => handleDelete(fileType.id)}
                    className="px-3 py-1 bg-red-500 text-white rounded hover:bg-red-600 transition duration-200"
                  >
                    Delete
                  </button>
                </div>
              </li>
            ))
          ) : (
            <p>No file types available.</p>
          )}
        </ul>
      </div>
    </>
  );
};

export default AddFileType;
