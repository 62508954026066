import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import Navbar from "../NavBar";


const CreateTeamLeader = () => {
    const [successMessage, setSuccessMessage] = useState('');
    const [markettingTypes, setMarkettingTypes] = useState([]);

    const [newTeamLeader, setNewTeamLeader] = useState({
        name: '',
        username: '',
        password: '',  // Make sure to add password state
        dept: '',     // Initialize dept as an empty string
        image: null,
    });

    // Function to handle form input changes
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setNewTeamLeader((prevTeamLeader) => ({
            ...prevTeamLeader,
            [name]: value,
        }));
    };

    // Function to handle dept select field change
    const handleDeptChange = (event) => {
        const { value } = event.target;
        setNewTeamLeader((prevTeamLeader) => ({
            ...prevTeamLeader,
            dept: value,
        }));
    };

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        setNewTeamLeader({ ...newTeamLeader, image: file });
    };

    // Function to handle form submission
    const handleFormSubmit = async (event) => {
        event.preventDefault();
        try {
            const formData = new FormData();
            formData.append('name', newTeamLeader.name);
            formData.append('username', newTeamLeader.username);
            formData.append('password', newTeamLeader.password);
            formData.append('dept', newTeamLeader.dept);
            formData.append('image', newTeamLeader.image);
    
            await axios.post('https://scoreboard.backenddd.in/LeadersTable/create_TeamLeader_api/', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
    
            setSuccessMessage('Data saved Successfully');
            // Clear the form after successful submission
            setNewTeamLeader({
                name: '',
                username: '',
                password: '',
                dept: '',
                image: null,
            });
        } catch (error) {
            console.error('Error saving Team Leader:', error);
        }
    };

    useEffect(() => {
        // Fetch the marketting types from your Django backend
        fetch('https://scoreboard.backenddd.in/LeadersTable/teamleader-types/')
            .then(response => response.json())
            .then(data => setMarkettingTypes(data))
            .catch(error => console.error('Error fetching marketting types:', error));
    }, []);


    return (
        <div>
             <Navbar />
            <div className="messagedi">
                <h1 className="text-center">Add Team Leader</h1>
                {successMessage && (
                    <p style={{ width: '50%', margin: 'auto', padding: '15px', backgroundColor: '#99BC85', color: 'white' }}>
                        {successMessage}
                    </p>
                )}
            </div>

            <div class="container mx-auto px-4" style={{ width: '50%', margin: 'auto', marginTop: '35px' }}>
                <form onSubmit={handleFormSubmit} encType="multipart/form-data">
                    <div class="grid grid-cols-2 gap-3">
                        <div class="col-span-2">
                            <label class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300" for="name">
                                Name
                            </label>
                            <input
                                class="block w-full px-4 py-2 text-sm text-gray-700 bg-white border rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring"
                                value={newTeamLeader.name}
                                onChange={handleInputChange}
                                type="text"
                                id="name"
                                name="name"
                                required
                            />
                        </div>
                        <div class="col-span-2">
                            <label class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300" for="username">
                                Username
                            </label>
                            <input
                                class="block w-full px-4 py-2 text-sm text-gray-700 bg-white border rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring"
                                value={newTeamLeader.username}
                                onChange={handleInputChange}
                                type="text"
                                id="username"
                                name="username"
                                required
                            />
                        </div>
                        <div class="col-span-2">
                            <label class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300" for="password">
                                Password
                            </label>
                            <input
                                class="block w-full px-4 py-2 text-sm text-gray-700 bg-white border rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring"
                                value={newTeamLeader.password}
                                onChange={handleInputChange}
                                type="password"
                                id="password"
                                name="password"
                                required
                            />
                        </div>
                        <div className="col-span-2">
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300" htmlFor="dept">Department</label>
                            <select
                                className="block w-full px-4 py-2 text-sm text-gray-700 bg-white border rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring"
                                value={newTeamLeader.dept}
                                onChange={handleDeptChange}
                                id="dept"
                                name="dept"
                                required
                            >
                                <option>Select Department</option>
                                {markettingTypes.map(type => (
                                    <option key={type.id} value={type.id} selected>{type.id}-{type.name}</option>
                                ))}
                            </select>
                        </div>
                        

                        <div class="col-span-2">
                            <label class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300" for="image">Image</label>
                            <input class="block w-full px-4 py-2 text-sm text-gray-700 bg-white border rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring" type="file" id="image" onChange={handleImageChange} name="image" required />
                        </div>
                    </div>

                    <div class="flex justify-end mt-4 mb-4">
                        <button
                            type="submit"
                            class="px-4 py-2 text-sm font-medium text-white bg-blue-500 rounded-md hover:bg-blue-600 focus:outline-none focus:ring"
                        >
                            Create
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default CreateTeamLeader;
