import React, { useState, useEffect } from 'react';
import Navbar from './NavBar';

const UserRecordComparison = () => {
    const [records, setRecords] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchRecords = async () => {
            setLoading(true);
            setError(null);
            try {
                const response = await fetch('https://scoreboard.backenddd.in/FilterMonthlyRecords/');
                if (!response.ok) throw new Error('Network response was not ok.');
                const data = await response.json();
                const groupedRecords = groupRecordsByUser(data);
                console.log(groupedRecords); // Debugging log to check the data
                setRecords(groupedRecords);
            } catch (error) {
                console.error('Error fetching data: ', error);
                setError(error.message);
            }
            setLoading(false);
        };

        fetchRecords();
    }, []);

    const groupRecordsByUser = (data) => {
        const grouped = {};
        data.current_month.forEach(record => {
            if (record.user__username) {
                grouped[record.user__username] = { ...grouped[record.user__username], current: record };
            }
        });
        data.last_month.forEach(record => {
            if (record.user__username) {
                grouped[record.user__username] = { ...grouped[record.user__username], last: record };
            }
        });
        return grouped;
    };

    if (loading) return <p>Loading records...</p>;
    if (error) return <p>Error: {error}</p>;
    if (Object.keys(records).length === 0) return <p>No records found.</p>;

    return (
        <div style={{ width: '100%' }}>
            <Navbar />
            <h1 className='text-center'>Performance Evaluation</h1>
            {Object.entries(records).map(([username, { current, last }], index) => {
                if (!username) return null; // Prevent rendering if username is undefined
                return (
                    <div key={username} style={{
                        marginBottom: '20px',
                        padding: '10px',
                        backgroundColor: index % 2 ? '#f0f0f0' : '#ffffff',
                        boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
                    }}>
                        <h3 style={{ color: '#333', borderBottom: '2px solid #007BFF', paddingBottom: '10px' }}>{username}</h3>
                        <table style={{ width: '100%', tableLayout: 'fixed', borderCollapse: 'collapse' }}>
                            <thead>
                                <tr style={{ backgroundColor: '#012030', color: '#ffffff' }}>
                                    <th>Month</th>
                                    <th>Resume Forwarded</th>
                                    <th>Selected</th>
                                    <th>Revenue</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td style={{ textAlign: 'center' }}>Current Month</td>
                                    <td style={{ textAlign: 'center' }}>{current?.total_resume_forwarded || 0}</td>
                                    <td style={{ textAlign: 'center' }}>{current?.total_selected || 0}</td>
                                    <td style={{ textAlign: 'center' }}>{current?.total_revenue || 0}</td>
                                </tr>
                                <tr>
                                    <td style={{ textAlign: 'center' }}>Last Month</td>
                                    <td style={{ textAlign: 'center' }}>{last?.total_resume_forwarded || 0}</td>
                                    <td style={{ textAlign: 'center' }}>{last?.total_selected || 0}</td>
                                    <td style={{ textAlign: 'center' }}>{last?.total_revenue || 0}</td>
                                </tr>
                                <tr style={{ fontWeight: 'bold', backgroundColor: '#E9ECEF' }}>
                                    <td style={{ textAlign: 'center' }}>Comparison</td>
                                    <td style={{ textAlign: 'center' }}>{(current?.total_resume_forwarded || 0) - (last?.total_resume_forwarded || 0)}</td>
                                    <td style={{ textAlign: 'center' }}>{(current?.total_selected || 0) - (last?.total_selected || 0)}</td>
                                    <td style={{ textAlign: 'center' }}>{(current?.total_revenue || 0) - (last?.total_revenue || 0)}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                );
            })}
        </div>
    );
};

export default UserRecordComparison;
