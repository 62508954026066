import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from "react-router-dom";

const CreateTargetForMarketting = () => {
    const [newMarketting, setNewMarketting] = useState({
        name: '',
        total_prefered_location: 0,
        ddE2E: 0,
        DDclient: 0,
        dailyVisit: 0,
        docladerId: 0,
        pace: 0,
        image: null,
    });
    const [markettingTypes, setMarkettingTypes] = useState([]);
    const [successMessage, setSuccessMessage] = useState('');

    

    // Function to handle form input changes
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setNewMarketting((prevMarketting) => ({
            ...prevMarketting,
            [name]: value,
        }));
    };

    useEffect(() => {
        // Fetch the marketting types from your Django backend
        fetch('https://scoreboard.backenddd.in/marketting-types/')
            .then(response => response.json())
            .then(data => setMarkettingTypes(data))
            .catch(error => console.error('Error fetching marketting types:', error));
    }, []);


    // Function to handle form submission
    const handleFormSubmit = async (event) => {
        event.preventDefault();
        try {
            const formData = new FormData();
            // append form data including the file
            formData.append('name', newMarketting.name);

            formData.append('total_prefered_location', newMarketting.total_prefered_location);
            formData.append('ddE2E', newMarketting.ddE2E);
            formData.append('DDclient', newMarketting.DDclient);
            formData.append('dailyVisit', newMarketting.dailyVisit);
            formData.append('docladerId', newMarketting.docladerId);
            formData.append('pace', newMarketting.pace);

            await axios.post('https://scoreboard.backenddd.in/type_of_marketting/create/', formData);
            // After successfully saving, fetch the updated list
            setSuccessMessage('Data saved Successfully');
            // Clear the form after successful submission
            setNewMarketting({
                name: '',
                total_prefered_location: 0,
                ddE2E: 0,
                DDclient: 0,
                dailyVisit: 0,
                docladerId: 0,
                pace: 0,
                image: null,
            });
        } catch (error) {
            console.error('Error saving marketting value:', error);
        }
    };

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        setNewMarketting({ ...newMarketting, image: file });
    };



    return (
        <div>
            <div className="messagedi" >
            <h1 className='text-center'>Add Marketting Member</h1>
            {successMessage && <p style={{width: "50%", margin: "auto", padding: "15px", backgroundColor: "#99BC85", color: "white"}}>{successMessage}</p>}</div>

            <div class="container mx-auto px-4" style={{ width: "50%", margin: "auto", marginTop: "35px" }}>
                {/* <div className="container" style={{ display: "flex", justifyContent: "space-between" }}>
                    <h1 class="text-3xl font-bold text-center">Performance Form</h1>
                    <Link to="/records" style={{ marginTop: "8px", border: "1px solid blue", padding: "15px", borderRadius: "7px 15px" }}>All Records</Link>
                </div> */}
                

                <form onSubmit={handleFormSubmit} method="post" encType="multipart/form-data">
                    <div class="grid grid-cols-2 gap-3">
                        <div class="col-span-2">
                            <label class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300" for="name">Name</label>
                            <input class="block w-full px-4 py-2 text-sm text-gray-700 bg-white border rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring" value={newMarketting.name} onChange={handleInputChange} type="text" id="name" name="name" required />
                        </div>
                       
                       
                        <div class="col-span-2">
                            <label class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300" for="resume_forwarded">Total Prefered Location</label>
                            <input class="block w-full px-4 py-2 text-sm text-gray-700 bg-white border rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring" type="number" value={newMarketting.total_prefered_location} onChange={handleInputChange} id="total_prefered_location" name="total_prefered_location" required />
                        </div>
                        <div class="col-span-2">
                            <label class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300" for="pipline">Pace</label>
                            <input class="block w-full px-4 py-2 text-sm text-gray-700 bg-white border rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring" type="number" value={newMarketting.pace} onChange={handleInputChange} id="pace" name="pace" required />
                        </div>
                        <div class="col-span-2">
                            <label class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300" for="turned_up">DD E2E</label>
                            <input class="block w-full px-4 py-2 text-sm text-gray-700 bg-white border rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring" type="number" id="ddE2E" value={newMarketting.ddE2E} onChange={handleInputChange} name="ddE2E"  />
                        </div>
                        <div class="col-span-2">
                            <label class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300" for="selected">DDclient</label>
                            <input class="block w-full px-4 py-2 text-sm text-gray-700 bg-white border rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring" type="number" id="DDclient" value={newMarketting.DDclient} onChange={handleInputChange} name="DDclient" required />
                        </div>
                        <div class="col-span-2">
                            <label class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300" for="target">Daily Visit</label>
                            <input class="block w-full px-4 py-2 text-sm text-gray-700 bg-white border rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring" type="number" id="dailyVisit" name="dailyVisit" value={newMarketting.dailyVisit} onChange={handleInputChange} required />
                        </div>
                        <div class="col-span-2">
                            <label class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300" for="target">docladder ID</label>
                            <input class="block w-full px-4 py-2 text-sm text-gray-700 bg-white border rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring" type="number" id="docladerId" name="docladerId" value={newMarketting.docladerId} onChange={handleInputChange} required />
                        </div>
                      
                    </div>

                    <div class="flex justify-end mt-4 mb-4">
                        <button type="submit" class="px-4 py-2 text-sm font-medium text-white bg-blue-500 rounded-md hover:bg-blue-600 focus:outline-none focus:ring">Create</button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default CreateTargetForMarketting;
