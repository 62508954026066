// Allannouncements.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Navbar from './NavBar';
import { Link } from "react-router-dom";
import { useLocation } from 'react-router-dom';



const Allannouncements = (props) => {
    console.log("typo checking")
    console.log("PRops: ", props.dataProp)
    const [announcements, setAnnouncements] = useState([]);

    const location = useLocation();
    const additionalData = location.state?.additionalProp;
    console.log("Additional Data: ", additionalData)

    useEffect(() => {
        const fetchAnnouncements = async () => {
            try {
                const response = await axios.get('https://scoreboard.backenddd.in/ShowAnnouncements/', {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
                      },
                }); // Replace with your API endpoint
                setAnnouncements(response.data);

                console.log(response.data)
               
            } catch (error) {
                console.error('Error fetching announcements:', error);
            }
        };

        fetchAnnouncements();
    }, []);


    const formatDateTime = (datetimeString) => {
        const options = { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' };
        return new Date(datetimeString).toLocaleString(undefined, options);
    };


    const handleDelete = async (id) => {
        try {
            // Make a DELETE request to your API endpoint
            const userConfirmed = window.confirm("Are you sure you want to delete?");

            // Proceed with deletion if user confirms
            if (userConfirmed) {
                // Make a DELETE request to your API endpoint
                await axios.delete(`https://scoreboard.backenddd.in/DeleteAnnouncement/${id}/`);

                // Remove the deleted announcement from the state
                setAnnouncements((prevAnnouncements) => prevAnnouncements.filter((announcement) => announcement.id !== id));
            }

        } catch (error) {
            console.error('Error deleting announcement:', error);
        }
    };

    return (
        <>

            {props.dataProp === "viewer_permission" ? (
                <></>
            ) : (
                <>
                    <Navbar dataProp="tlandbh" />
                </>
            )}

            <table className="min-w-full bg-white border border-gray-300" style={{ width: "95%", margin: "auto" }}>
                <thead>
                    <tr>
                        <th scope="col" className="py-2 px-4 border-b">Announcement</th>
                        <th scope="col" className="py-2 px-4 border-b">Announcement Date</th>
                        {/* <th scope="col" className="py-2 px-4 border-b">Announced By</th> */}
                        <th scope="col" className="py-2 px-4 border-b">Expiry Date</th>
                        <th scope="col" className="py-2 px-4 border-b">Options</th>
                    </tr>
                </thead>
                <tbody className="divide-y divide-gray-300 text-center">
                    {announcements.map((announcement) => (
                        <tr key={announcement.id}>
                            <td className="py-2 px-4 border-b">{announcement.name}</td>
                            <td className="py-2 px-4 border-b">{formatDateTime(announcement.dateadded)}</td>
                            {/* <td className="py-2 px-4 border-b">{announcement.byuser}</td> */}
                            <td className="py-2 px-4 border-b">{formatDateTime(announcement.activity_date)}</td>
                            <td className="py-2 px-4 border-b"><button
                                className="btn btn-primary"
                                onClick={() => handleDelete(announcement.id)}
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash-fill" viewBox="0 0 16 16">
                                    <path d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5M8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5m3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0" />
                                </svg>
                            </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
                <Link to="/createannouncements">
                    <button className="fixed bottom-5 left-8 bg-blue-700 text-white p-4 rounded-full">
                        +
                    </button>
                </Link>
            </table>
        </>
    );
};

export default Allannouncements;
