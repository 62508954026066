import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Navbar from './../NavBar';
import { Link } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import DOMPurify from 'dompurify';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import SummaryCell from './SummaryCell';

const AllMinutesOfMeeting = (props) => {
    const [MinutesOfMeeting, setMinutesOfMeeting] = useState([]);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [editData, setEditData] = useState({ id: '', title: '', momdate: '', summary: '' });
    const [isViewModalOpen, setIsViewModalOpen] = useState(false);
    const [viewSummary, setViewSummary] = useState('');

    const location = useLocation();
    const additionalData = location.state?.userPermissions || props.dataProp;
    console.log("Location State User Permissions: ", location.state?.userPermissions);
    console.log("Props Data Prop: ", props.dataProp);
    console.log("Additional Data: ", additionalData);

    useEffect(() => {
        const fetchMinutesOfMeeting = async () => {
            try {
                const response = await axios.get('https://scoreboard.backenddd.in/api/moms/', {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
                    },
                });
                setMinutesOfMeeting(response.data);
            } catch (error) {
                console.error('Error fetching MinutesOfMeeting:', error);
            }
        };

        fetchMinutesOfMeeting();
    }, []);

    const formatDateTime = (datetimeString) => {
        const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
        return new Date(datetimeString).toLocaleString(undefined, options);
    };

    const handleDelete = async (id) => {
        try {
            const userConfirmed = window.confirm("Are you sure you want to delete?");
            if (userConfirmed) {
                await axios.delete(`https://scoreboard.backenddd.in/api/moms/${id}/`);
                setMinutesOfMeeting((prevMinutesOfMeeting) => prevMinutesOfMeeting.filter((MoM) => MoM.id !== id));
            }
        } catch (error) {
            console.error('Error deleting MoM:', error);
        }
    };

    const handleEditClick = (MoM) => {
        setEditData(MoM);
        setIsEditModalOpen(true);
    };

    const handleEditChange = (e) => {
        const { name, value } = e.target;
        setEditData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleEditSave = async () => {
        try {
            const response = await axios.put(`https://scoreboard.backenddd.in/api/moms/${editData.id}/`, editData, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('access_token')}`,
                },
            });
            setMinutesOfMeeting(prevState => prevState.map(mom => mom.id === editData.id ? response.data : mom));
            setIsEditModalOpen(false);
        } catch (error) {
            console.error('Error updating MoM:', error);
        }
    };

    const createMarkup = (html) => {
        return { __html: DOMPurify.sanitize(html) };
    };

    const handleViewClick = (summary) => {
        setViewSummary(summary);
        setIsViewModalOpen(true);
    };

    const renderEditModal = () => (
        <div style={modalOverlayStyle}>
            <div style={modalContentStyle}>
                <h2 style={modalTitleStyle}>Edit Minutes of Meeting</h2>
                <form style={formStyle}>
                    <div style={formGroupStyle}>
                        <label style={labelStyle}>Title</label>
                        <input
                            type="text"
                            name="title"
                            value={editData.title}
                            onChange={handleEditChange}
                            style={inputStyle}
                        />
                    </div>
                    <div style={formGroupStyle}>
                        <label style={labelStyle}>MoM Date</label>
                        <input
                            type="date"
                            name="momdate"
                            value={editData.momdate}
                            onChange={handleEditChange}
                            style={inputStyle}
                        />
                    </div>
                    <div style={formGroupStyle}>
                        <label style={labelStyle}>Summary</label>
                        <CKEditor
                            editor={ClassicEditor}
                            data={editData.summary}
                            onChange={(event, editor) => {
                                const data = editor.getData();
                                setEditData(prevState => ({
                                    ...prevState,
                                    summary: data
                                }));
                            }}
                        />
                    </div>
                    <div style={buttonGroupStyle}>
                        <button
                            type="button"
                            onClick={() => setIsEditModalOpen(false)}
                            style={cancelButtonStyle}
                        >
                            Cancel
                        </button>
                        <button
                            type="button"
                            onClick={handleEditSave}
                            style={saveButtonStyle}
                        >
                            Save
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );

    const renderViewModal = () => (
        <div style={modalOverlayStyle}>
            <div style={modalContentStyle}>
                <h2 style={modalTitleStyle}>View Summary</h2>
                <div style={viewSummaryStyle} dangerouslySetInnerHTML={createMarkup(viewSummary)}></div>
                <div style={buttonGroupStyle}>
                    <button
                        type="button"
                        onClick={() => setIsViewModalOpen(false)}
                        style={closeButtonStyle}
                    >
                        Close
                    </button>
                </div>
            </div>
        </div>
    );

    return (
        <>
            {additionalData === "custom_operation" ? (
                <>
                    <Navbar dataProp="custom_operation" />
                </>
            ) : (
                <>
                    <Navbar dataProp="tlandbh" />
                </>
            )}

            {isEditModalOpen && renderEditModal()}
            {isViewModalOpen && renderViewModal()}

            <table className="min-w-full bg-white border border-gray-300" style={{ width: "95%", margin: "auto" }}>
                <thead>
                    <tr>
                        <th scope="col" className="py-2 px-4 border-b">MoM</th>
                        <th scope="col" className="py-2 px-4 border-b">MoM Date</th>
                        <th scope="col" className="py-2 px-4 border-b">Summary</th>
                        <th scope="col" className="py-2 px-4 border-b">Options</th>
                    </tr>
                </thead>
                <tbody className="divide-y divide-gray-300 text-center">
                    {MinutesOfMeeting.map((MoM) => (
                        <tr key={MoM.id}>
                            <td className="py-2 px-4 border-b">{MoM.title}</td>
                            <td className="py-2 px-4 border-b">{formatDateTime(MoM.momdate)}</td>
                            <SummaryCell summary={MoM.summary} />
                            <td className="py-2 px-4 border-b">
                                <button
                                    className="btn btn-primary mr-2"
                                    onClick={() => handleEditClick(MoM)}
                                >
                                    Edit
                                </button>
                                <button
                                    className="btn btn-primary"
                                    onClick={() => handleDelete(MoM.id)}
                                >
                                    Delete
                                </button>
                                <button
                                    className="btn btn-primary ml-2"
                                    onClick={() => handleViewClick(MoM.summary)}
                                >
                                    <i className="fas fa-eye"></i>
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
                <Link to="/createmom">
                    <button className="fixed bottom-5 left-8 bg-blue-700 text-white p-4 rounded-full">
                        +
                    </button>
                </Link>
            </table>
        </>
    );
};

const modalOverlayStyle = {
    position: 'fixed',
    inset: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 1000,
    overflow: 'auto',
};

const modalContentStyle = {
    backgroundColor: 'white',
    padding: '1.5rem',
    borderRadius: '0.5rem',
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.25)',
    maxWidth: '90%',
    maxHeight: '90%',
    overflowY: 'auto',
};

const modalTitleStyle = {
    fontSize: '1.25rem',
    marginBottom: '1rem',
};

const formStyle = {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
};

const formGroupStyle = {
    display: 'flex',
    flexDirection: 'column',
    gap: '0.5rem',
};

const labelStyle = {
    fontWeight: 'bold',
    color: '#333',
};

const inputStyle = {
    padding: '0.5rem',
    border: '1px solid #ccc',
    borderRadius: '0.25rem',
};

const buttonGroupStyle = {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: '0.5rem',
};

const cancelButtonStyle = {
    padding: '0.5rem 1rem',
    backgroundColor: '#555',
    color: 'white',
    borderRadius: '0.25rem',
    cursor: 'pointer',
};

const saveButtonStyle = {
    padding: '0.5rem 1rem',
    backgroundColor: '#007bff',
    color: 'white',
    borderRadius: '0.25rem',
    cursor: 'pointer',
};

const closeButtonStyle = {
    padding: '0.5rem 1rem',
    backgroundColor: '#007bff',
    color: 'white',
    borderRadius: '0.25rem',
    cursor: 'pointer',
};

const viewSummaryStyle = {
    marginBottom: '1rem',
    maxHeight: '400px',
    overflowY: 'auto',
};

export default AllMinutesOfMeeting;
